import React from "react";
import { Prompt } from "react-router-dom";
import _set from "lodash.set";
import _isEqual from "lodash.isequal";
import _cloneDeep from "lodash.clonedeep";
import queryString from "query-string";
import { injectStripe } from "react-stripe-elements";
import { isMobileView, Navigation, sum } from "../../lib";
import * as formFunctions from "./FormLogic";

import GeneralSettings from "./enrollmentSettings/GeneralSettings";
import Pricing from "./enrollmentSettings/pricing/Pricing";
import Tours from "./enrollmentSettings/tours/Tours";
import ConfirmationModal from "../../components/ConfirmationModal";

class PegishaSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      id,
      acceptancePolicyOverride,
      attendees,
      campuses,
      emailRecipients,
      promoCodes,
      latestCancellationDateOverride,
      specialInstructions,
      studentRegistrationEndDateOverride,
      tracks,
      tours,
    } = this.props.trip.enrollment;
    const {
      defaultShliachAcceptancePolicy,
      id: eventID,
      latestCancellationDate,
      studentRegistrationEndDate,
    } = this.props.trip.event;
    this.state = {
      enrollmentSettings: {
        id: id,
        tripEventID: eventID,
        attendees: attendees || [],
        campuses: campuses || [],
        emailRecipients: emailRecipients || [],
        acceptancePolicyOverride:
          acceptancePolicyOverride || defaultShliachAcceptancePolicy,
        tracks: tracks || [],
        promoCodes: promoCodes || [],
        specialInstructions: specialInstructions || "",
        studentRegistrationEndDateOverride:
          studentRegistrationEndDateOverride ||
          studentRegistrationEndDate ||
          "",
        latestCancellationDateOverride:
          latestCancellationDateOverride || latestCancellationDate || "",
        tours: tours || [],
        overlappingSchedules: false,
        acceptedTerms: [],
        didAcceptTermsAndConditions: true,
        billing: {
          stripeToken: null,
          address: {
            id: 0,
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            discriminator: "",
          },
          cardHolderFullName: "",
          useCardOnFile: !!this.props.account.credCardInfo,
          saveNewCardToFile: true,
          hasCompleteCardInfo: false,
          ccRequired: false,
        },
      },
      errorMessage: "",
      selectedTab: "general",
      submitAttempted: false,
      submitting: false,
      successMessage: "",
      validation: {},
      checkForShliachAttendee: true,
      showAttendeeWarningModal: false,
      optOutWarningTourIDs: [],
    };

    this.state.initialEnrollmentSettings = _cloneDeep(
      this.state.enrollmentSettings,
    );

    this.formFunctions = {};
    Object.keys(formFunctions).forEach(
      (func) => (this.formFunctions[func] = formFunctions[func].bind(this)),
    );
  }

  async componentDidMount() {
    let { tab: page, sub: tab } = this.props.pageRoute.query;
    if (page === "settings") {
      this.props.actions.getGeneralSettings(this.props.shliachID);
      if (isMobileView()) {
        tab = "all";
        this.setState({ selectedTab: tab });
      } else {
        if (tab === undefined) {
          tab = "general";
          this.navigate(tab);
        } else {
          this.setState({ selectedTab: tab });
        }
      }
    }
    this._ismounted = true;
    await this.setEnrollmentTracks();
    await this.setEnrollmentTours();
    this.state.initialEnrollmentSettings = _cloneDeep(
      this.state.enrollmentSettings,
    );

    this.setOnRefreshPrompt();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      pageRoute: {
        query: { tab },
      },
    } = this.props;
    if (prevProps.pageRoute.query.tab !== tab) {
      this.props.actions.getGeneralSettings(this.props.shliachID);
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
    this.removeOnRefreshPrompt();
  }

  setEnrollmentTracks = () => {
    const { tracks: enrolledTracks } = this.props.trip.enrollment;
    const { tracks } = this.props.trip.event;
    const enrollmentTracks = enrolledTracks.map((track) => {
      const pricingTrack = tracks.filter((t) => t.id === track.trackID)[0];
      return {
        id: track.id,
        trackID: track.trackID,
        trackName: track.trackName,
        earlyBirdStudentDeadline: track.earlyBirdStudentDeadline,
        earlyBirdPriceOverride:
          track.earlyBirdPriceOverride != null
            ? track.earlyBirdPriceOverride.toString()
            : pricingTrack.earlyBirdStudentPrice,
        earlyBirdPlaceholder:
          pricingTrack && pricingTrack.earlyBirdStudentPrice,
        regularPriceOverride:
          track.regularPriceOverride !== null
            ? track.regularPriceOverride.toString()
            : pricingTrack.regularStudentPrice,
        regularPlaceholder: pricingTrack && pricingTrack.regularStudentPrice,
      };
    });
    this.handleFormChange("tracks", enrollmentTracks);
  };

  // setEnrollmentPromoCodes = () => {
  //   const { promoCodes } = this.props.trip.enrollment;
  //   const enrollmentPromoCodes = promoCodes.map(promoCode => {
  //     return {
  //       id: promoCode.id,
  //       code: promoCode.code,
  //       title: promoCode.title,
  //       discountAmount: promoCode.discountAmount.toString(),
  //       threshold
  //     };
  //   });
  //   this.handleFormChange("promoCodes", enrollmentPromoCodes);
  // };

  setEnrollmentTours = () => {
    const { tours: enrolledTours } = this.props.trip.enrollment;
    const { tours } = this.props.trip.event;

    const enrollmentTours = enrolledTours
      .map((tour) => {
        const eventTour = tours.filter((t) => t.id === tour.tourID)[0];
        let enrolledTour = {
          autoEnrollStudentsOverride: tour.autoEnrollStudentsOverride,
          id: tour.id,
          isOhelVisit: eventTour.isOhelVisit,
          schedules: eventTour.schedules,
          tourID: tour.tourID,
          tourScheduleIDs: tour.tourScheduleIDs,
          numberOfChaperones: tour.numberOfChaperones,
        };
        if (eventTour.hasTransportation) {
          enrolledTour = {
            ...enrolledTour,
            transportationOption: tour.transportationOption || "",
          };
        }
        return enrolledTour;
      })
      .filter((t) => !!t);

    if (this._ismounted) this.handleFormChange("tours", enrollmentTours);
  };

  navigate = (page) => {
    this.setState({ selectedTab: page });
    !isMobileView() &&
      Navigation.redirect(
        `${this.props.pageRoute.location.pathname}?tab=${this.props.pageRoute.query.tab}&sub=${page}`,
      );
  };

  onChangeTourEnrollment = (optInValue, tour) => {
    //enrolling in the tour and/or changing autoEnrollStudentsOverride settings
    let enrolledTours = [...this.state.enrollmentSettings.tours];
    if (optInValue) {
      const {
        autoEnrollStudents,
        autoEnrollStudentsOverride,
        id,
        isOhelVisit,
        schedules,
        numberOfChaperones,
      } = tour;

      const enrolledTourIndex = enrolledTours.findIndex((t) => t.tourID === id);
      if (enrolledTourIndex > -1) {
        enrolledTours[enrolledTourIndex].autoEnrollStudentsOverride =
          autoEnrollStudentsOverride;
      } else {
        const existingTour = this.state.initialEnrollmentSettings.tours.find(
          (t) => t.tourID === id,
        );
        if (existingTour) {
          enrolledTours.push({
            ...existingTour,
            autoEnrollStudentsOverride: autoEnrollStudentsOverride,
          });
        } else {
          enrolledTours.push({
            tourID: id,
            autoEnrollStudentsOverride:
              autoEnrollStudentsOverride || autoEnrollStudents,
            tourScheduleIDs:
              schedules.length === 1 && schedules[0].numberOfSlotsLeft !== 0
                ? [schedules[0].id]
                : [],
            isOhelVisit: isOhelVisit,
            schedules: schedules,
            numberOfChaperones: numberOfChaperones,
          });
        }
      }
    } else {
      enrolledTours = enrolledTours.filter(({ tourID }) => tourID !== tour.id);
    }

    this.handleFormChange("tours", enrolledTours);
  };

  handleChange = (name, value) => {
    let state = _cloneDeep(this.state);
    _set(state, name, value);

    return new Promise((resolve) => {
      this.setState(state, () => {
        resolve();
      });
    });
  };

  handleArrayChange = async (name, value, status) => {
    let newEnrollmentSettings;
    let array = this.state.enrollmentSettings[name]
      ? [...this.state.enrollmentSettings[name]]
      : [];
    if (status === true) {
      newEnrollmentSettings = {
        ...this.state.enrollmentSettings,
        [name]: [...this.state.enrollmentSettings[name], value],
      };
      if (name === "campuses") {
        newEnrollmentSettings[name].sort((a, b) => (a.id > b.id ? 1 : -1));
      }
    } else {
      let index;
      if (name === "campuses") {
        index = array.findIndex((i) => i.id === value.id);
      } else {
        index = array.indexOf(value);
      }
      if (index !== -1 || typeof value === "undefined") {
        array.splice(index, 1);
        newEnrollmentSettings = {
          ...this.state.enrollmentSettings,
          [name]: array,
        };
      }
    }
    return new Promise((resolve, reject) => {
      this.setState(
        {
          enrollmentSettings: newEnrollmentSettings,
        },
        async () => {
          if (
            this.formFunctions.hasValidationErrors() ||
            this.state.submitAttempted
          ) {
            await this.validateForm();
          }
          resolve();
        },
      );
    });
  };

  handleFormChange = (name, value) => {
    const state = _cloneDeep(this.state.enrollmentSettings);
    const newEnrollmentSettings = _set(state, name, value);

    this.setState({
      enrollmentSettings: newEnrollmentSettings,
    });

    return new Promise((resolve, reject) => {
      this.setState(
        {
          enrollmentSettings: newEnrollmentSettings,
        },
        async () => {
          if (
            this.formFunctions.hasValidationErrors() ||
            this.state.submitAttempted
          ) {
            await this.validateForm();
          }
          resolve();
        },
      );
    });
  };

  setOptOutWarning = (optInValue, tourID) => {
    if (
      !optInValue &&
      this.state.initialEnrollmentSettings.tours.find(
        (t) => t.tourID === tourID,
      )
    ) {
      this.setState({
        optOutWarningTourIDs: [...this.state.optOutWarningTourIDs, tourID],
      });
    } else {
      this.setState({
        optOutWarningTourIDs: this.state.optOutWarningTourIDs.filter(
          (id) => id !== tourID,
        ),
      });
    }
  };

  checkCCRequired = () => {
    const { enrollmentSettings } = this.state;
    const { credCardInfo } = this.props.account;
    const promoCodesTotal = sum(
      enrollmentSettings.promoCodes.map((p) => p.discountAmount),
    );
    const mayOweMoneyForTrack = enrollmentSettings.tracks.map((track) => {
      if (
        track.earlyBirdPriceOverride - promoCodesTotal <
          track.earlyBirdPlaceholder ||
        track.regularPriceOverride - promoCodesTotal < track.regularPlaceholder
      ) {
        return true;
      } else {
        return false;
      }
    });
    const ccRequired = mayOweMoneyForTrack.indexOf(true) > -1;
    if (ccRequired !== enrollmentSettings.billing.ccRequired && !credCardInfo) {
      this.handleFormChange("billing.ccRequired", ccRequired);
    }
  };

  validateForm = async () => {
    await this.checkCCRequired();
    const validation = this.formFunctions.getFormValidation();

    await this.handleChange("validation", validation);

    const errorMessage = this.formFunctions.getValidationErrorMessage();
    this.setState({ errorMessage });
  };

  onSubmit = async () => {
    await this.validateForm();
    this.setState({ submitAttempted: true, submitting: true });
    const hasValidationErrors = Object.keys(this.state.validation).length > 0;

    // validate that at least 1 shliach is selected as attendee
    if (
      !_isEqual(
        this.state.enrollmentSettings.attendees,
        this.state.initialEnrollmentSettings.attendees,
      ) &&
      this.state.checkForShliachAttendee
    ) {
      const foundShliachAttendee = this.state.enrollmentSettings.attendees.find(
        (a) => !a.isChaperone,
      );
      if (!foundShliachAttendee) {
        this.setState({ submitting: false, showAttendeeWarningModal: true });
        return;
      }
    }

    if (hasValidationErrors) {
      this.setState({ submitting: false });
      return;
    }

    const { updatedEnrollmentSettings, stripeValues } =
      this.formFunctions.getValuesForSubmission();
    await this.props.actions.submitTripEnrollment(
      false,
      updatedEnrollmentSettings,
      stripeValues && stripeValues,
      this.state.enrollmentSettings.billing.ccRequired &&
        !this.props.account.credCardInfo &&
        this.props.stripe.createToken,
      !this.props.account.credCardInfo && this.props.shliachID,
    );
    if (!this.props.trip.enrollment.success) {
      this.setState({
        submitting: false,
        errorMessage: this.props.trip.errorMessage,
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 3000);
    } else {
      this.setEnrollmentTours();
      // set attendees to new data so that all saved attendees have id
      // really, all data should be reloaded - RP
      const updatedEnrollmentSettings = {
        ...this.state.enrollmentSettings,
        attendees: this.props.trip.enrollment?.attendees,
      };
      this.setState({
        submitting: false,
        errorMessage: "",
        successMessage: "Your changes have been saved",
        checkForShliachAttendee: true,
        optOutWarningTourIDs: [],
        enrollmentSettings: updatedEnrollmentSettings,
        initialEnrollmentSettings: _cloneDeep(updatedEnrollmentSettings),
      });
      setTimeout(() => {
        this.setState({ successMessage: "" });
      }, 1500);
    }
  };

  onNavigationPromptMessage = (promptRoute) => {
    const {
      pageRoute: { location },
    } = this.props;
    const { pathname, search } = promptRoute;
    const { tab } = queryString.parse(search);

    if (
      pathname !== location.pathname ||
      !tab ||
      tab.toLowerCase() !== "settings"
    ) {
      return "Your changes have not been saved. Are you sure you want to leave this page?";
    }

    return true; //no prompt when route and tab are unchanged [for sub-tabs navigation]
  };

  setOnRefreshPrompt = () => {
    window.onbeforeunload = () => {
      return (
        !_isEqual(
          this.state.enrollmentSettings,
          this.state.initialEnrollmentSettings,
        ) || undefined
      );
    };
  };

  removeOnRefreshPrompt = () => {
    window.onbeforeunload = null;
  };

  render() {
    const {
      errorMessage,
      selectedTab,
      validation,
      submitting,
      submitAttempted,
      successMessage,
      enrollmentSettings,
      initialEnrollmentSettings,
      showAttendeeWarningModal,
      optOutWarningTourIDs,
    } = this.state;
    const { settings: { loading } = {} } = this.props.trip;
    let dirty = !_isEqual(enrollmentSettings, initialEnrollmentSettings);
    const hasValidationErrors = Object.keys(this.state.validation).length > 0;
    return (
      <div className="full-width trip-settings-page">
        <Prompt when={dirty} message={this.onNavigationPromptMessage} />
        <div className="flex tablet-block">
          <div className="card full-width trip-card trip-settings-card">
            <div
              className="trip-page-form-section"
              style={{ paddingBottom: "0" }}
            >
              {!isMobileView() && (
                <ul className="trip-settings-tabs">
                  <li>
                    <p
                      className={selectedTab === "general" ? "active" : ""}
                      onClick={() => this.navigate("general")}
                    >
                      General
                    </p>
                  </li>
                  <li>
                    <p
                      className={selectedTab === "pricing" ? "active" : ""}
                      onClick={() => this.navigate("pricing")}
                    >
                      Pricing
                    </p>
                  </li>
                  <li>
                    <p
                      className={selectedTab === "tours" ? "active" : ""}
                      onClick={() => this.navigate("tours")}
                    >
                      Tours
                    </p>
                  </li>
                </ul>
              )}
            </div>
            {(selectedTab === "general" || isMobileView()) && (
              <GeneralSettings
                hasEnrollment={true}
                enrollmentSettings={enrollmentSettings}
                event={this.props.trip.event}
                onArrayChange={this.handleArrayChange}
                onChange={this.handleFormChange}
                selectedTab={selectedTab}
                trip={this.props.trip}
                validation={validation}
                navigate={this.navigate}
                submitAttempted={submitAttempted}
              />
            )}
            {(selectedTab === "pricing" || isMobileView()) && (
              <Pricing
                account={this.props.account}
                countries={this.props.sys.countries}
                enrollmentSettings={enrollmentSettings}
                enrollment={this.props.trip.enrollment}
                event={this.props.trip.event}
                navigate={this.navigate}
                onArrayChange={this.handleArrayChange}
                onChange={this.handleFormChange}
                selectedTab={selectedTab}
                hasEnrollment={true}
                submitForm={this.submitForm}
                trip={this.props.trip}
                validation={validation}
              />
            )}
            {(selectedTab === "tours" || isMobileView()) && (
              <Tours
                event={this.props.trip.event}
                enrollmentSettings={enrollmentSettings}
                initialTours={initialEnrollmentSettings.tours}
                navigate={this.navigate}
                onChange={this.handleFormChange}
                onChangeTourEnrollment={this.onChangeTourEnrollment}
                selectedTab={selectedTab}
                hasEnrollment={true}
                validation={validation}
                tourTransportationOptions={
                  this.props.sys.tourTransportationOptions
                }
                ohelTourTransportationOptions={
                  this.props.sys.ohelTourTransportationOptions
                }
                optOutWarningTourIDs={optOutWarningTourIDs}
                setOptOutWarning={this.setOptOutWarning}
              />
            )}
          </div>
          {!loading && (
            <div className="trip-page-form-btns relative">
              <div>
                <button
                  className="btn btn-accent"
                  onClick={this.onSubmit}
                  disabled={!dirty || errorMessage || hasValidationErrors}
                >
                  {submitting ? "Saving..." : "Save"}
                </button>
                {dirty && !errorMessage ? (
                  <p className="accent-text-dark xs-text flex flex-align-center ml-16 mt-8">
                    <i className="material-icons medium-text mr-8">save</i>
                    Unsaved changes
                  </p>
                ) : (
                  <p
                    className="accent-text-dark xs-text flex flex-align-center ml-16 mt-8 line-height-double"
                    style={{ maxWidth: "104px" }}
                  >
                    {successMessage !== "" ? (
                      <i className="material-icons medium-text mr-8">check</i>
                    ) : null}
                    {successMessage}
                  </p>
                )}
                {errorMessage && (
                  <p className="error-message fw-600">{errorMessage}</p>
                )}
              </div>
            </div>
          )}
        </div>

        {showAttendeeWarningModal && (
          <ConfirmationModal
            cancel={() => {
              this.setState({
                showAttendeeWarningModal: false,
              });
            }}
            confirm={() => {
              this.setState({
                checkForShliachAttendee: false,
                showAttendeeWarningModal: false,
              });
              this.onSubmit();
            }}
            title={"No Shliach/Shlucha Added"}
            confirmText={"Save Anyway"}
            secondOptionText={"Add Shliach"}
            onClickSecondOption={() => {
              this.setState({
                showAttendeeWarningModal: false,
              });
              this.navigate("general");
            }}
            message={
              "Don't send your students to pegisha, bring your students to pegisha. At least one Shliach or Shlucha should attend."
            }
            show={true}
          />
        )}
      </div>
    );
  }
}

export default injectStripe(PegishaSettings);

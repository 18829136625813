import React, { Fragment, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import {
  CheckboxInput,
  FormErrorsWatch,
  FormFieldWatch,
  FormRadioGroupField,
  FormToggleField,
  getSetFieldValueOptions,
} from "../../../../../components/react-hook-form";
import { CampusInfoProps } from "./CampusInfo";
import { CampusStudentResource } from "../../ProfileFormHelpers";
import {
  ProfileFormGridItemStyled,
  ProfileFormGridStyled,
} from "../../ProfileForm.styles";
import { getProfileSectionId, ProfileSectionWrapper } from "../../profileMenu";
import { SystemSelectors } from "../../../../../state";
import { useMobile } from "../../../../../themes";
import { Option } from "../../../../../lib/types";

const NoStudentResourceProviderValue = "NA";
const NoStudentGroupValue = "None";

export const CampusJewishStudentLifeInfo = React.memo(
  /**
   *
   */
  function CampusJewishStudentLifeInfo(props: CampusInfoProps) {
    const { campusIndex, form, intersectionOptions } = props;

    const {
      chabadStudentGroupStatuses = [],
      chaplainStatuses = [],
      studentGroupTypes = [],
      studentResourceProviders = [],
      studentResources = [],
    } = useSelector(SystemSelectors.allSysLists);

    const campusFieldsAccessor = `campuses.${campusIndex}`;

    const categorizedStudentResources = useMemo(() => {
      const categorizedStudentResources: Record<
        string,
        { id: number; name: string; categoryDisplay: string }[]
      > = {};

      studentResources.forEach((r: any) => {
        if (!categorizedStudentResources[r.categoryDisplay]) {
          categorizedStudentResources[r.categoryDisplay] = [];
        }
        categorizedStudentResources[r.categoryDisplay].push(r);
      });

      return categorizedStudentResources;
    }, [studentResources]);

    const handleStudentResourceChange = useCallback(
      (studentResourceId: number, provider: string, checked: boolean) => {
        let updatedCampusStudentResources = [
          ...form.getValues().campuses[campusIndex].studentResources,
        ];

        if (checked) {
          if (provider === NoStudentResourceProviderValue) {
            // if provider is N/A, clear out other providers for the resource
            updatedCampusStudentResources =
              updatedCampusStudentResources.filter(
                (r) => r.studentResourceID !== studentResourceId,
              );
          } else if (
            updatedCampusStudentResources.some(
              (r) =>
                r.studentResourceID === studentResourceId &&
                r.provider === NoStudentResourceProviderValue,
            )
          ) {
            // otherwise, exclude N/A selection for the resource
            updatedCampusStudentResources =
              updatedCampusStudentResources.filter(
                (r) =>
                  !(
                    r.studentResourceID === studentResourceId &&
                    r.provider === NoStudentResourceProviderValue
                  ),
              );
          }

          updatedCampusStudentResources.push({
            studentResourceID: studentResourceId,
            provider,
          });
        } else {
          updatedCampusStudentResources = updatedCampusStudentResources.filter(
            (r) =>
              r.studentResourceID !== studentResourceId ||
              r.provider !== provider,
          );
        }

        form.setValue(
          `campuses.${campusIndex}.studentResources`,
          updatedCampusStudentResources,
          getSetFieldValueOptions(form),
        );
      },
      [campusIndex, form],
    );

    const handleStudentGroupChange = useCallback(
      (studentGroupType: string, checked: boolean) => {
        let updatedCampusStudentGroups = [
          ...form.getValues().campuses[campusIndex].studentGroups,
        ];

        if (checked) {
          if (studentGroupType === NoStudentGroupValue) {
            // if group type is None, clear student groups selection
            updatedCampusStudentGroups = [];
          } else if (updatedCampusStudentGroups.includes(NoStudentGroupValue)) {
            // otherwise, exclude None selection
            updatedCampusStudentGroups = updatedCampusStudentGroups.filter(
              (g) => g !== NoStudentGroupValue,
            );
          }

          updatedCampusStudentGroups.push(studentGroupType);
        } else {
          updatedCampusStudentGroups = updatedCampusStudentGroups.filter(
            (g) => g !== studentGroupType,
          );
        }

        form.setValue(
          `campuses.${campusIndex}.studentGroups`,
          updatedCampusStudentGroups,
          getSetFieldValueOptions(form),
        );
      },
      [campusIndex, form],
    );

    const isMobile = useMobile();
    return (
      <ProfileSectionWrapper
        id={getProfileSectionId("campusJewishStudentLife", campusIndex)}
        intersectionOptions={intersectionOptions}
        view="desktop"
      >
        <Typography variant="h6">Jewish student life</Typography>
        <FormErrorsWatch>
          {(errors) => {
            const studentResourcesError =
              errors.campuses?.[campusIndex]?.studentResources;
            return (
              <>
                <Typography
                  variant="body2"
                  id={`${campusFieldsAccessor}.studentResources`}
                  color={studentResourcesError ? "error" : "textSecondary"}
                  style={{ marginTop: "6px", marginBottom: "24px" }}
                >
                  Please select who provides student resources. If a resource
                  isn't available, select n/a.
                  {studentResourcesError
                    ? " A selection is required for each resource."
                    : ""}
                </Typography>
                <FormFieldWatch
                  name={`${campusFieldsAccessor}.studentResources`}
                >
                  {(campusStudentResources: CampusStudentResource[] = []) => (
                    <>
                      {Object.keys(categorizedStudentResources).map(
                        (categoryDisplay) => {
                          return (
                            <Grid
                              container
                              spacing={2}
                              key={categoryDisplay}
                              style={{ marginBottom: "24px" }}
                            >
                              <Grid item sm={4} xs={12}>
                                <Typography
                                  color="textSecondary"
                                  variant="overline"
                                >
                                  {categoryDisplay}
                                </Typography>
                              </Grid>
                              {!isMobile &&
                                studentResourceProviders.map(
                                  (provider: any) => (
                                    <Grid item sm={2} key={provider.enumValue}>
                                      <Typography
                                        color="textSecondary"
                                        variant="overline"
                                      >
                                        {provider.displayValue}
                                      </Typography>
                                    </Grid>
                                  ),
                                )}
                              {categorizedStudentResources[categoryDisplay].map(
                                (studentResource) => {
                                  const campusStudentResourceProviders =
                                    campusStudentResources
                                      .filter(
                                        (r) =>
                                          r.studentResourceID ===
                                          studentResource.id,
                                      )
                                      .map((r) => r.provider);
                                  const requiredResourceError =
                                    !!studentResourcesError &&
                                    !campusStudentResourceProviders.length;
                                  return (
                                    <Fragment key={studentResource.id}>
                                      <Grid
                                        item
                                        sm={4}
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          color={
                                            requiredResourceError
                                              ? "error"
                                              : undefined
                                          }
                                          variant="body1"
                                        >
                                          {studentResource.name.replace(
                                            " minyan", // remove 'minyan' from minyan-related resource names, per design requirements
                                            "",
                                          )}
                                        </Typography>
                                      </Grid>
                                      {studentResourceProviders.map(
                                        (provider: any) => {
                                          const key = `${studentResource.id}-${provider.enumValue}`;
                                          const checked =
                                            campusStudentResourceProviders.includes(
                                              provider.enumValue,
                                            );

                                          return (
                                            <Grid
                                              item
                                              sm={2}
                                              key={key}
                                              style={
                                                isMobile
                                                  ? { paddingTop: 0 }
                                                  : {}
                                              }
                                            >
                                              <CheckboxInput
                                                checked={checked}
                                                error={requiredResourceError}
                                                name={key}
                                                id={`${key}-${campusIndex}`}
                                                label={provider.displayValue}
                                                onChange={(_, val) =>
                                                  handleStudentResourceChange(
                                                    studentResource.id,
                                                    provider.enumValue,
                                                    val,
                                                  )
                                                }
                                              />
                                            </Grid>
                                          );
                                        },
                                      )}
                                    </Fragment>
                                  );
                                },
                              )}
                            </Grid>
                          );
                        },
                      )}
                    </>
                  )}
                </FormFieldWatch>
              </>
            );
          }}
        </FormErrorsWatch>
        <FormErrorsWatch>
          {(errors) => {
            const studentGroupsError =
              errors.campuses?.[campusIndex]?.studentGroups;
            return (
              <>
                <Typography color="textSecondary" variant="overline">
                  Jewish student groups
                </Typography>
                {studentGroupsError && (
                  <Typography
                    color="error"
                    id={`${campusFieldsAccessor}.studentGroups`}
                    style={{ marginLeft: "8px" }}
                    variant="caption"
                  >
                    {studentGroupsError.message}
                  </Typography>
                )}
                <FormFieldWatch name={`${campusFieldsAccessor}.studentGroups`}>
                  {(campusStudentGroups: string[] = []) => (
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "24px" }}
                    >
                      {studentGroupTypes.map((studentGroupType: any) => {
                        const checked = campusStudentGroups.includes(
                          studentGroupType.enumValue,
                        );
                        return (
                          <Grid
                            item
                            sm={6}
                            xs={12}
                            style={isMobile ? { paddingTop: 0 } : undefined}
                            key={studentGroupType.enumValue}
                          >
                            <CheckboxInput
                              checked={checked}
                              error={studentGroupsError}
                              label={studentGroupType.displayValue}
                              name={studentGroupType.enumValue}
                              id={`${campusIndex}-${studentGroupType.enumValue}`}
                              onChange={(_, val) =>
                                handleStudentGroupChange(
                                  studentGroupType.enumValue,
                                  val,
                                )
                              }
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </FormFieldWatch>
              </>
            );
          }}
        </FormErrorsWatch>
        <Typography color="textSecondary" variant="overline">
          School recognition of chabad
        </Typography>
        <ProfileFormGridStyled
          style={{ marginTop: "0px", marginBottom: "50px" }}
        >
          <ProfileFormGridItemStyled sm={6} xs={12}>
            <ToggleOrRadioField
              isMobile={isMobile}
              label="Is the Shliach a recognized chaplain?"
              name={`${campusFieldsAccessor}.shliachChaplainStatus`}
              options={chaplainStatuses.map((s: any) => ({
                id: s.enumValue,
                name: s.displayValue,
              }))}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled sm={6} xs={12}>
            <ToggleOrRadioField
              isMobile={isMobile}
              label="Is the Shlucha a recognized chaplain?"
              name={`${campusFieldsAccessor}.shluchaChaplainStatus`}
              options={chaplainStatuses.map((s: any) => ({
                id: s.enumValue,
                name: s.displayValue,
              }))}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled sm={12} xs={12}>
            <ToggleOrRadioField
              isMobile={isMobile}
              label="Is Chabad a recognized student group?"
              name={`${campusFieldsAccessor}.chabadStudentGroupStatus`}
              options={chabadStudentGroupStatuses.map((s: any) => ({
                id: s.enumValue,
                name: s.displayValue,
              }))}
            />
          </ProfileFormGridItemStyled>
        </ProfileFormGridStyled>
      </ProfileSectionWrapper>
    );
  },
);

interface ToggleOrRadioProps {
  isMobile: boolean;
  label: string;
  name: string;
  options: Option[];
}

function ToggleOrRadioField({
  isMobile,
  label,
  name,
  options,
}: ToggleOrRadioProps) {
  return isMobile ? (
    <FormRadioGroupField name={name} options={options} label={label} />
  ) : (
    <FormToggleField
      fullWidth={false}
      label={label}
      name={name}
      options={options}
    />
  );
}

import React, { useState, useEffect } from "react";
import { Collapse } from "@material-ui/core";
import Toggle from "../../../components/form/Toggle";

export default function RequireDonorFieldsSection(props) {
  const { onChange, requireDonorAddress, requireDonorPhone, showAllExpanded } =
    props;
  const [showExpanded, setShowExpanded] = useState(showAllExpanded);
  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };

  useEffect(() => {
    setShowExpanded(showAllExpanded);
  }, [showAllExpanded]);

  return (
    <div className="raffles-form-section">
      <div className="raffles-section-title" onClick={handleToggleExpand}>
        <div className="flex">
          <p className="large-text fw-900 flex flex-align-center pointer mb-8">
            Require donor fields
          </p>
        </div>
        {showExpanded ? (
          <i className="material-icons accent-text-secondary xl-text">remove</i>
        ) : (
          <i className="material-icons accent-text-secondary xl-text">add</i>
        )}
      </div>

      <Collapse in={showExpanded}>
        <div className="expanded-section">
          <p className="accent-text-dark mb-24 line-height-double">
            The more info needed, the less likely donors are to complete
            purchase.
          </p>
          <div className="flex flex-align-center">
            <p className="fs-13 accent-text" style={{ width: "98px" }}>
              Address
            </p>
            <Toggle
              name="requireDonorAddress"
              onChange={onChange}
              options={[
                {
                  value: true,
                  display: "Required",
                },
                {
                  value: false,
                  display: "Optional",
                },
              ]}
              value={requireDonorAddress}
            />
          </div>
          <div className="flex flex-align-center mt-24">
            <p className="fs-13 accent-text" style={{ width: "98px" }}>
              Phone
            </p>
            <Toggle
              name="requireDonorPhone"
              onChange={onChange}
              options={[
                {
                  value: true,
                  display: "Required",
                },
                {
                  value: false,
                  display: "Optional",
                },
              ]}
              value={requireDonorPhone}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
}

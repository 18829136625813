import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Typography } from "@material-ui/core";

import { PageLink, formatCurrency, isMobileView } from "../../../lib";
import Pages from "../..";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";
import { AuthSelectors, RaffleSelectors } from "../../../state";

export default function RaffleInfoCard() {
  const [showRaffleInfo, setShowRaffleInfo] = useState(true);

  const raffle = useSelector(RaffleSelectors.raffle);
  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);

  useEffect(() => {
    if (isMobileView()) {
      setShowRaffleInfo(false);
    }
  }, []);

  const toggleRaffleInfo = () => {
    setShowRaffleInfo(!showRaffleInfo);
  };

  const {
    details: {
      endDate,
      hebrewEndDate,
      hebrewStartDate,
      loading,
      officeStaff,
      programID = 0,
      raffleTitle,
      startDate,
    } = {},
    metrics: { totalRaised, totalTicketsSold } = {},
    enrollmentDetails: { currencyCode, donorSiteURL, id } = {},
  } = raffle;

  const raffleStartDate = new Date(startDate).toLocaleDateString("en-us", {
    month: "short",
    day: "numeric",
  });

  const raffleEndDate = new Date(endDate).toLocaleDateString("en-us", {
    month: "short",
    day: "numeric",
  });
  return (
    <div className="raffles-sidebar-card raffles-info">
      {loading ? (
        <div className="medium-loader">
          <Loader />
        </div>
      ) : (
        <>
          <div>
            <div className="flex flex-justify-space flex-align-center">
              <Typography variant="subtitle1">{raffleTitle} Info</Typography>
              <i
                className="material-icons desktop-hidden pointer large-text"
                onClick={toggleRaffleInfo}
              >
                {showRaffleInfo ? "remove" : "add"}
              </i>
            </div>
            {showRaffleInfo && (
              <>
                <div className="mt-24 mb-24">
                  <label className="mb-8">Dates</label>
                  {raffleStartDate && raffleEndDate ? (
                    <>
                      <p className="flex flex-align-center mb-8">
                        <i className="material-icons mr-8 large-text">
                          calendar_today
                        </i>
                        <span className="small-text">
                          {hebrewStartDate} - {hebrewEndDate}
                        </span>
                      </p>
                      <p className="flex flex-align-center">
                        <i className="material-icons mr-8 large-text">
                          calendar_today
                        </i>
                        <span className="small-text">
                          {raffleStartDate} - {raffleEndDate}
                        </span>
                      </p>
                    </>
                  ) : (
                    <p className="flex flex-align-center">
                      <i className="material-icons mr-8 large-text">
                        calendar_today
                      </i>
                      <span className="small-text">TBD</span>
                    </p>
                  )}
                </div>
                {officeStaff && (
                  <>
                    <div className="mb-8">
                      <label>Program Coordinator</label>
                      <p>{officeStaff.fullName}</p>
                    </div>
                    <div className="program-contact-info">
                      <p>
                        <i className="material-icons">phone</i>
                        <a
                          href={`tel:${officeStaff.primaryPhone}, ${officeStaff.primaryPhoneExt}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {officeStaff.primaryPhone}
                          {officeStaff.primaryPhoneExt && (
                            <span className="ml-8 small-text">
                              Ext: {officeStaff.primaryPhoneExt}
                            </span>
                          )}
                        </a>
                      </p>

                      <p>
                        <i className="material-icons">email</i>
                        <a
                          href={`mailto:${officeStaff.primaryEmail}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {officeStaff.primaryEmail}
                        </a>
                      </p>
                    </div>
                  </>
                )}
                {isShliach && (
                  <div className="program-contact-info">
                    <p className="flex flex-align-center">
                      <i className="material-icons large-text accent-text mr-8">
                        open_in_new
                      </i>
                      <PageLink
                        to={Pages.programs.programDetails}
                        params={{ programId: programID }}
                        className="link-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {raffleTitle} Info
                      </PageLink>
                    </p>
                  </div>
                )}

                {id && isShliach && (
                  <div className="program-total-info">
                    <p>
                      <span
                        className="fw-700 inline-block mb-12"
                        style={{ width: "120px" }}
                      >
                        Total Raised
                      </span>
                      <span>{formatCurrency(totalRaised, currencyCode)}</span>
                    </p>
                    <p>
                      <span
                        className="fw-700 inline-block"
                        style={{ width: "120px" }}
                      >
                        Tickets Sold
                      </span>
                      <NumberFormat
                        thousandSeparator={true}
                        value={totalTicketsSold ? totalTicketsSold : 0}
                        displayType={"text"}
                      />
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          {donorSiteURL && showRaffleInfo && (
            <div className="raffles-link flex flex-align-center flex-justify-space mt-24">
              <p>{donorSiteURL}</p>
              <a
                href={donorSiteURL}
                target="_blank"
                rel="noopener noreferrer"
                className="link-text small-text flex flex-align-center ml-16 no-wrap"
              >
                <i className="material-icons medium-text mr-8">open_in_new</i>
                Go to Link
              </a>
            </div>
          )}
        </>
      )}
    </div>
  );
}

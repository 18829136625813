export const Statuses = {
  Approved: "Approved",
  Rejected: "Rejected",
  Pending: "Pending",
};

export const EduPageViews = {
  courses: "courses",
  students: "students",
  billing: "billing",
  settings: "settings",
};

export const EduCourseViews = {
  manage: "manage",
  offer: "offer",
  overview: "overview",
};

export const EduCourseListTypes = {
  my: "my",
  all: "all",
};

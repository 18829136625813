import React from "react";
import ActivateEnrollment from "./ActivateEnrollment";
import EnrollmentSettings from "../myEdu/settings/EnrollmentSettings";
import StripeProvider from "../../../components/StripeProvider";
import { EduProgramTypes } from "../eduUtils";
import { injectStripe } from "react-stripe-elements";

const { REACT_APP_STRIPE_API_KEY } = process.env;

const EnrollmentFormSettings = injectStripe(EnrollmentSettings);

export default class EnrollmentForm extends React.PureComponent {
  render() {
    const {
      enrollment: {
        data: { eduProgramType },
      },
    } = this.props;

    return eduProgramType === EduProgramTypes.JewishU ||
      eduProgramType === EduProgramTypes.YourIsrael ? (
      <StripeProvider apiKey={REACT_APP_STRIPE_API_KEY}>
        <EnrollmentFormSettings {...this.props} newEnrollment={true} />
      </StripeProvider>
    ) : (
      <ActivateEnrollment {...this.props} />
    );
  }
}

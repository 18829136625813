import { RaffleActions } from "./actions";

export const RaffleState = {
  name: "raffle",
  persist: false,
  defaults: {},
  handlers: {
    [RaffleActions.SET_RAFFLE_LOADING]: function setRaffleLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [RaffleActions.SET_RAFFLE_DETAILS_LOADING]:
      function setRaffleDetailsLoading(state, action) {
        return {
          ...state,
          details: {
            loading: !!action.payload,
          },
        };
      },
    [RaffleActions.SET_RAFFLE_ORDERS_LOADING]: function setRaffleOrdersLoading(
      state,
      action,
    ) {
      return {
        ...state,
        orders: {
          loading: !!action.payload,
        },
      };
    },
    [RaffleActions.SET_RAFFLE_DONORS_LOADING]: function setRaffleOrdersLoading(
      state,
      action,
    ) {
      return {
        ...state,
        donors: {
          loading: !!action.payload,
        },
      };
    },
    [RaffleActions.SET_RAFFLE_METRICS_LOADING]:
      function setRaffleMetricsLoading(state, action) {
        return {
          ...state,
          metricsLoading: !!action.payload,
        };
      },
    [RaffleActions.SET_RAFFLE_SALES_LOADING]: function setRaffleSalesLoading(
      state,
      action,
    ) {
      return {
        ...state,
        salesLoading: !!action.payload,
      };
    },
    [RaffleActions.SET_RAFFLE_SALES_COMPARISON_LOADING]:
      function setRaffleSalesComparisonLoading(state, action) {
        return {
          ...state,
          salesComparisonLoading: !!action.payload,
        };
      },
    [RaffleActions.SET_RAFFLE_TOP_SELLERS_LOADING]:
      function setRaffleTopSellersLoading(state, action) {
        return {
          ...state,
          topSellersLoading: !!action.payload,
        };
      },
    [RaffleActions.SET_RAFFLE_TEAMS_LOADING]: function setRaffleTeamsLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [RaffleActions.UPDATE_RAFFLE]: function updateRaffle(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

import React from "react";

export default class ActivateEnrollment extends React.PureComponent {
  state = {
    errorMessage: "",
    submitAttempted: false,
    termsAndConditions: [
      {
        term: "I understand that students need to register for the Your Israel course through the COCI system.",
        didAccept: false,
      },
      {
        term: "I agree to hold at least six, 90-minute classes, about Israel, in which at least 10-15 of the same students will be enrolled for the duration of the program.",
        didAccept: false,
      },
      {
        term: "I agree to take high-quality pictures to share with my post-program survey.",
        didAccept: false,
      },
      {
        term: "I agree to complete a post-program survey, created by COCI, to be submitted within two weeks of completing the course.",
        didAccept: false,
      },
      {
        term: "I understand that the funds allocated may be used at my discretion solely for Your Israel program purposes. I understand that once the program is complete I will fill out an expense sheet of what the funds were used for (as part of the post-program survey provided by COCI).",
        didAccept: false,
      },
      {
        term: "I understand that the student stipend will be sent from COCI directly to the students in the form of a virtual gift card.",
        didAccept: false,
      },
      {
        term: "I understand that funding is sent from COCI within 6-8 weeks from when all surveys are received, as long as COCI received the funds from MTF. Until then, all money must be laid out.",
        didAccept: false,
      },
      {
        term: "I agree that each student will have to fill out a post-program survey created by COCI, and no funding will be received until all student- surveys from my campus have been submitted.",
        didAccept: false,
      },
      {
        term: "I agree that failure to meet these terms will result in forfeiting any funding towards this program.",
        didAccept: false,
      },
    ],
  };

  activateEnrollment = async () => {
    this.setState({ errorMessage: "", submitAttempted: true });

    if (!this.didAccceptTermsAndConditions()) {
      return;
    }

    const enrollmentForSubmission = {
      ...this.props.enrollment.data,
      isPending: false,
    };

    //set showEnrollmentCompleted to true before submit action to ensure that it will mount immediately upon activation
    const { toggleShowEnrollmentCompleted } = this.props;
    if (toggleShowEnrollmentCompleted) {
      toggleShowEnrollmentCompleted();
    }

    await this.props.actions.submitEduEnrollment(enrollmentForSubmission);

    const {
      submitEnrollment: { error, errorMessage },
    } = this.props;

    if (error) {
      this.setState({
        errorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
      toggleShowEnrollmentCompleted(); //toggle back showEnrollmentCompleted on failure
    }
  };

  didAccceptTermsAndConditions = () =>
    !this.state.termsAndConditions.some((tc) => !tc.didAccept);

  onToggleAcceptTerm = (termIndex, didAccept) => {
    const termsAndConditions = [...this.state.termsAndConditions];
    termsAndConditions[termIndex].didAccept = didAccept;
    this.setState({ termsAndConditions });
  };

  render() {
    const {
      enrollment: { data: { eduProgramName } = {} },
      submitEnrollment: { loading },
    } = this.props;
    const { errorMessage, submitAttempted, termsAndConditions } = this.state;

    return (
      <div
        className="flex flex-align-center flex-justify-center full-page-state"
        style={{ height: "auto" }}
      >
        <img src="/images/confirmEnrollment.svg" alt="" height="240" />
        <p className="fw-700 xxl-text mt-24 mb-24">
          Confirm Enrollment in {eduProgramName}
        </p>
        <p className="medium-text mb-16">
          Confirm and activate your {eduProgramName} account today.
        </p>
        <div className="line-height-double small-text mb-40">
          <p className="fw-700 text-center mb-16">
            Confirming your enrollment indicates you agree to the following
            Terms and Conditions:
          </p>
          {termsAndConditions.map((tc, index) => (
            <div
              className={`custom-checkbox-container edu-enrollment-checkbox mb-8 ${
                submitAttempted && !tc.didAccept ? "error" : ""
              }`}
              key={index}
            >
              <input
                checked={tc.didAccept}
                className="custom-checkbox"
                id={`term-${index}`}
                onChange={(e) =>
                  this.onToggleAcceptTerm(index, e.target.checked)
                }
                type="checkbox"
              />
              <label htmlFor={`term-${index}`}>{tc.term}</label>
            </div>
          ))}
        </div>
        <button
          className="btn btn-accent btn-large"
          disabled={loading}
          onClick={this.activateEnrollment}
          style={{ padding: "0 40px", maxWidth: "98%" }}
        >
          {loading ? "Activating Enrollment..." : "Confirm Enrollment"}
        </button>
        {submitAttempted && !this.didAccceptTermsAndConditions() ? (
          <p className="error-text mt-8">
            Please accept all terms and conditions in order to continue
          </p>
        ) : (
          !loading &&
          errorMessage && <p className="error-text mt-8">{errorMessage}</p>
        )}
      </div>
    );
  }
}

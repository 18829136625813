import React from "react";
import { Prompt } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Switch,
  Typography,
  Tooltip,
  Button,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import Select from "react-select";
import NumberFormat from "react-number-format";
import ConfirmationModal from "../../../../../../components/ConfirmationModal";
import CopyToClipboardTextbox from "../../../../../../components/CopyToClipboardTextbox";
import ImageUpload from "../../../../../../components/formik/ImageWithCropUpload";
import Checkbox from "../../../../../../components/form/Checkbox";
import TagInput from "../../../../../../components/form/TagInput";
import MultiSelect from "../../../../../../components/form/MultiSelect";
import {
  getFormattedValuesForForm,
  flattenArray,
  removeEmptyFromObj,
  Navigation,
  PageURL,
  partition,
  AuthTypes,
} from "../../../../../../lib";
import {
  rsvpMessageTypes,
  sendResourceSmsMessage,
} from "../../../RsvpMessageUtils";
import RsvpCustomQuestion from "../../../settings/RsvpCustomQuestion";
import { getFieldOptionsForProgramType } from "../../../RsvpUtils";
import { ResourceStates } from "../../../../RsvpHelpers";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";
import ReservationConfirmationMessageSettings from "../../../shared/manage/ReservationConfirmationMessageSettings";

export default class ResourceSettings extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      ...this.getInitializedSettings(),
      errorMessage: "",
      requestedNavigation: false,
      showUnlimitedCapacityConfirmationModal: false,
      showNotifyStudentsAboutResourceUpdateModal: false,
      submitAction: null,
      submitAttempted: false,
    };

    state.initialState = _cloneDeep(state);

    this.state = state;

    this.isShliach = props.auth.loggedInAs === AuthTypes.shliach;
  }

  incompleteErrorMessage = "Please complete required or invalid fields";

  actionTypes = {
    cancel: "cancel",
    submit: "submit",
  };

  componentDidMount() {
    // retrieve all chabad house tags for permission settings
    this.props.actions.getAllChabadHouseTags();
  }

  getInitializedSettings = () => {
    const {
      enrollment: { data: { registrationQuestions } = {} },
      resource: { data: resource = {} },
      isNew,
    } = this.props;

    let initializedSettings;
    if (isNew) {
      initializedSettings = {
        accessTags: [],
        abbreviatedName: "",
        askForDonation: false,
        description: "",
        duration: "LessThanHalfHour",
        isIsraelRelated: false,
        isPrivate: false,
        registrationFeeAmount: null,
        state: ResourceStates.Available,
        imageURL: "",
        maxCapacity: "",
        name: "",
        shortDescription: "",
        typeID: "",
        customConfirmationMessage: "",
        registrationQuestions: [],
        allRegistrationQuestions: registrationQuestions,
      };
    } else {
      initializedSettings = getFormattedValuesForForm(resource);
      //sorting sms codes so that the primary one is first
      initializedSettings.smsCodes = initializedSettings.smsCodes?.length
        ? [
            initializedSettings.smsCodes.find((c) => c.isPrimary).code,
            ...initializedSettings.smsCodes
              .filter((c) => !c.isPrimary)
              .map((c) => c.code),
          ]
        : [];
    }

    return {
      settings: initializedSettings,
      overrideDefaultConfirmationMessage:
        initializedSettings.customConfirmationMessage ? "true" : "false",
    };
  };

  goToChabadHouseActivitySettings = () => {
    // const { chabadHouseId } = this.state.settings;

    Navigation.go(
      PageURL.to(
        // chabadHouseId
        //   ? `/account/chabadHouse/${chabadHouseId}`:
        "/account", //TODO scroll to chabad house section
        // null,
        // {
        //   tab: "activities",
        // },
      ),
    );
  };

  onChangeSettings = (name, value, otherChanges) => {
    if (this.props.submitResource.loading) {
      return;
    }

    let settings = _cloneDeep(this.state.settings);
    _set(settings, name, value);

    if (otherChanges) {
      Object.keys(otherChanges).forEach((change) =>
        _set(settings, change, otherChanges[change]),
      );
    }

    return new Promise((resolve) => {
      this.setState({ settings }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        const { errorMessage } = this.state;
        if (errorMessage && errorMessage === this.incompleteErrorMessage) {
          const isValid = this.validateSettings();
          if (isValid) {
            this.setState({
              errorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeSettingsEvt = ({ target: { name, type, value } }) => {
    //do not allow negative number inputs
    if (type === "number" && value && value < 0) {
      value = "";
    }
    if (name === "abbreviatedName") {
      value = value.toUpperCase();
    }
    return this.onChangeSettings(name, value);
  };

  onChangeState = (name, value) => {
    this.setState({ [name]: value });
  };

  onAddRemoveQuestion = (questionIndex) => {
    let additionalQuestions = _cloneDeep(
      this.state.settings.registrationQuestions,
    );

    if (questionIndex >= 0) {
      //delete question

      //remove the question
      additionalQuestions = additionalQuestions.filter(
        (_, qIndex) => questionIndex !== qIndex,
      );
    } else {
      //add question
      additionalQuestions.push({
        isActive: true,
        isEventSpecific: false,
        isResourceSpecific: false,
        isMandatory: false,
        questionText: "",
        responseType: "",
      });
    }

    this.onChangeSettings("registrationQuestions", additionalQuestions);
  };

  onCancelSettingsChanges = () => {
    this.setState(this.state.initialState, this.props.pageRoute.history.goBack);
  };

  onSubmitSettings = async (bypassUnlimitedCapacityPrompt = false) => {
    this.setState({
      errorMessage: "",
      submitActionType: this.actionTypes.submit,
      submitAttempted: true,
    });

    const isValid = this.validateSettings();
    if (!isValid) {
      this.setState({
        errorMessage: this.incompleteErrorMessage,
      });
      return;
    }

    const { isNew } = this.props;
    const {
      initialState: { settings: initialSettings },
      settings,
    } = this.state;

    const validateCapacity =
      (!settings.maxCapacity || settings.maxCapacity === "0") &&
      (isNew || initialSettings.maxCapacity);
    if (validateCapacity) {
      this.setState({
        showUnlimitedCapacityConfirmationModal: !bypassUnlimitedCapacityPrompt,
      });

      if (!bypassUnlimitedCapacityPrompt) {
        return;
      }
    }

    if (
      settings.registrationQuestions.some(
        (q) => q.isActive && (!q.questionText || !q.responseType),
      )
    ) {
      return;
    }

    const resourceSettingsForSubmission = _cloneDeep(settings);
    removeEmptyFromObj(resourceSettingsForSubmission);

    resourceSettingsForSubmission.smsCodes = settings.smsCodes?.map((c, i) => ({
      code: c,
      isPrimary: i === 0,
    }));

    await this.props.actions.submitRsvpResource(resourceSettingsForSubmission);

    const {
      onCreate,
      submitResource: { error, errorMessage },
    } = this.props;

    if (error) {
      this.setState({
        errorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    } else {
      if (isNew) {
        this.setState({ requestedNavigation: true }, onCreate);
      } else {
        //refresh resources in dropdown if updated resource name or state (active/inactive)
        if (
          resourceSettingsForSubmission.name !== initialSettings.name ||
          resourceSettingsForSubmission.state !== initialSettings.state
        ) {
          this.props.actions.getRsvpResourcesBasic();
        }

        //re-initialize state with resource settings
        const updatedState = {
          ...this.state.initialState,
          ...this.getInitializedSettings(),
        };
        updatedState.initialState = _cloneDeep(updatedState);
        this.setState(updatedState);

        if (
          this.valueChanged(
            resourceSettingsForSubmission.registrationFeeAmount,
            initialSettings.registrationFeeAmount,
          ) &&
          this.isShliach
        ) {
          this.setState({ showNotifyStudentsAboutResourceUpdateModal: true });
        }
      }
    }
  };

  valueChanged = (originalValue, newValue) =>
    (!!originalValue || !!newValue) && originalValue !== newValue;

  validateSettings = () => {
    const {
      enrollment: {
        data: { isChabadHouseEnrolledInMessaging },
      },
    } = this.props;
    const {
      settings: {
        smsCodes,
        description,
        duration,
        name,
        registrationFeeAmount,
        shortDescription,
        typeID,
      },
    } = this.state;

    if (
      !description ||
      !name ||
      !duration ||
      !typeID ||
      (isChabadHouseEnrolledInMessaging &&
        (!smsCodes?.length || !shortDescription))
    ) {
      return false;
    }

    if (registrationFeeAmount === 0) {
      return false;
    }

    return true;
  };

  render() {
    const {
      actions,
      chabadHouseTags: {
        data: chabadHouseTagOptions = [],
        error: chabadHouseTagsError,
      } = {},
      enrollment: {
        data: {
          chabadHouseMessagingNumber,
          chabadHouseRsvpURL,
          chabadHouseHasPaymentProviders,
          isChabadHouseEnrolledInMessaging,
          forChabadHouseID,
          programID,
          programScheduleID,
        } = {},
      },
      isNew,
      submitResource: { loading },
      sys: { interactionDurations = [], interactionTypes = [] },
    } = this.props;

    const {
      settings,
      errorMessage,
      initialState: { settings: initialSettings },
      requestedNavigation,
      showUnlimitedCapacityConfirmationModal,
      showNotifyStudentsAboutResourceUpdateModal,
      submitActionType,
      submitAttempted,
      overrideDefaultConfirmationMessage,
    } = this.state;

    const {
      accessTags,
      description,
      duration,
      isIsraelRelated,
      registrationFeeAmount,
      resourceId,
      state,
      imageURL,
      isPrivate,
      maxCapacity,
      name,
      askForDonation,
      shortDescription,
      typeID,
      customConfirmationMessage,
      registrationQuestions: thisResourceQuestions,
      allRegistrationQuestions,
      smsCodes,
    } = settings;

    const { durationOptions, typeOptions } = getFieldOptionsForProgramType(
      "Rsvp",
      interactionDurations,
      interactionTypes,
    );

    const hasChanges = !_isEqual(settings, initialSettings);

    const splitQuestions = partition(
      allRegistrationQuestions,
      (q) => q.isResourceSpecific,
    );

    const resourceSpecificQuestions = splitQuestions[0] ?? [];
    const questionsForAllResources = splitQuestions[1] ?? [];

    const additionalQuestions = thisResourceQuestions
      ?.map((q, index) => ({ ...q, index }))
      ?.filter((q) => !q.id);

    return (
      <React.Fragment>
        <Prompt
          when={!requestedNavigation && hasChanges}
          message={`Your ${
            isNew
              ? "settings have not been submitted"
              : "changes have not been saved"
          }. Are you sure you want to leave this page?`}
        />
        <div className="rsvp-event-settings-form">
          <div>
            <Box className="line-height-double flex flex-justify-space">
              <Typography variant="h5">
                {isNew ? "Add New Resource" : "Settings"}
              </Typography>
              <Box>
                <span className="accent-text small-text mr-12 mobile-hidden">
                  Students can only view and sign up for active resources
                </span>
                <FormControlLabel
                  control={
                    <Switch
                      className="resource-state-switch"
                      name="state"
                      onChange={(e) =>
                        this.onChangeSettings(
                          "state",
                          e.target.checked
                            ? ResourceStates.Available
                            : ResourceStates.Unavailable,
                        )
                      }
                      checked={state === ResourceStates.Available}
                    />
                  }
                  label="Active"
                />
              </Box>
            </Box>
            <span className="accent-text small-text desktop-hidden tablet-hidden">
              Students can only view and sign up for active resources
            </span>
          </div>
          <Box className="mt-24 pt-24">
            <Typography variant="h6" className="mb-16">
              Resource Name and Description
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={2} xs={12}>
                <Typography className="mr-24 accent-text-dark">
                  Resource Name
                </Typography>
              </Grid>
              <Grid item md={10} xs={12}>
                <input
                  className={`custom-input ${
                    submitAttempted && !name ? "error" : ""
                  }`}
                  name="name"
                  onChange={this.onChangeSettingsEvt}
                  type="text"
                  value={name}
                />
              </Grid>
              {isChabadHouseEnrolledInMessaging && (
                <>
                  <Grid item md={2} xs={12}>
                    <Typography className="mr-24 accent-text-dark flex">
                      Abbreviated Name
                      <Tooltip
                        title="You can create multiple codes for this resource. 
                        Simply press enter or tab after entering each code to add another. 
                        The first code will be the default sent out in all SMS messages. 
                        The others can be a backup in case of a typo, etc."
                      >
                        <Info className="ml-8 medium-text" />
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item md={10} xs={12}>
                    <TagInput
                      className={`mobile-height-auto ${
                        submitAttempted && !smsCodes?.length ? "error" : ""
                      }`}
                      containerStyle={{
                        minHeight: 46,
                      }}
                      name="smsCodes"
                      tags={smsCodes || []}
                      onChange={(name, tags) => {
                        const upperCase = tags.map((t) => t.toUpperCase());
                        this.onChangeSettings(name, upperCase);
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item md={2} xs={12}>
                <Typography className="mr-24 accent-text-dark flex">
                  Resource Description
                  <Tooltip title="Full description will appear on the website">
                    <Info className="ml-8 medium-text" />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item md={10} xs={12}>
                <textarea
                  className={`custom-input custom-message mt-8 ${
                    submitAttempted && !description ? "error" : ""
                  }`}
                  name="description"
                  onChange={this.onChangeSettingsEvt}
                  placeholder="Enter description here..."
                  style={{ marginLeft: 0 }}
                  value={description}
                />
              </Grid>

              {isChabadHouseEnrolledInMessaging && (
                <>
                  <Grid item md={2} xs={12}>
                    <Typography className="mr-24 accent-text-dark flex">
                      Short Description
                      <Tooltip title="100 character max description for use with SMS messaging">
                        <Info className="ml-8 medium-text" />
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item md={10} xs={12}>
                    <textarea
                      className={`custom-input custom-message mt-8 ${
                        submitAttempted && !shortDescription ? "error" : ""
                      }`}
                      maxLength={100}
                      name="shortDescription"
                      onChange={this.onChangeSettingsEvt}
                      placeholder="Enter short description here..."
                      style={{ marginLeft: 0, height: "60px" }}
                      value={shortDescription}
                    />
                    <p className="total-characters" style={{ right: "46px" }}>
                      {shortDescription.length} / 100
                    </p>
                  </Grid>
                </>
              )}
              {/* TODO add note about generating interactions?? */}
              <Grid item md={2} xs={12}>
                <Typography className="mr-24 accent-text-dark flex">
                  Resource Type
                  {this.isShliach && (
                    <Tooltip title="Type will be used for student interaction tracking purposes, and will not be displayed to the student on the public site">
                      <Info className="ml-8 medium-text" />
                    </Tooltip>
                  )}
                </Typography>
              </Grid>
              <Grid item md={10} xs={12}>
                <Select
                  className={`custom-select ${
                    submitAttempted && !typeID ? "error" : ""
                  }`}
                  classNamePrefix="custom-select"
                  isSearchable={true}
                  onChange={({ value }) =>
                    this.onChangeSettings("typeID", value)
                  }
                  options={typeOptions}
                  value={
                    typeID &&
                    flattenArray(
                      typeOptions.map(({ options }) => options),
                    ).find(
                      ({ value }) =>
                        value && value.toString() === typeID.toString(),
                    )
                  }
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Typography className="mr-24 accent-text-dark flex">
                  Duration
                  {this.isShliach && (
                    <Tooltip
                      title="Duration will be used for student interaction tracking
                        purposes, and will not be displayed to the student on
                        the public site"
                    >
                      <Info className="ml-8 medium-text" />
                    </Tooltip>
                  )}
                </Typography>
              </Grid>
              <Grid item md={10} xs={12}>
                <Select
                  className={`custom-select ${
                    submitAttempted && !duration ? "error" : ""
                  }`}
                  classNamePrefix="custom-select"
                  onChange={({ value }) =>
                    this.onChangeSettings("duration", value)
                  }
                  options={durationOptions}
                  value={
                    duration &&
                    durationOptions.find(
                      ({ value }) => value.toString() === duration.toString(),
                    )
                  }
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Typography className="mr-24 accent-text-dark flex">
                  Resource price
                  {!chabadHouseHasPaymentProviders && this.isShliach && (
                    <span className="tooltip-container flex ml-8 mobile-full-width">
                      <i className="material-icons link-text no-hover medium-text">
                        info
                      </i>
                      <span className="tooltip">
                        In order to set a registration fee, complete the Stripe
                        account section in your Chabad House profile{" "}
                        <span
                          style={{
                            textDecoration: "underline",
                            color: "blue",
                            cursor: "pointer",
                          }}
                          onClick={this.goToChabadHouseActivitySettings}
                        >
                          here
                        </span>
                      </span>
                    </span>
                  )}
                </Typography>
              </Grid>
              <Grid item md={10} xs={12} className="flex flex-column">
                <NumberFormat
                  allowNegative={false}
                  className={`custom-input dollar-input 
                  ${
                    submitAttempted && registrationFeeAmount === 0
                      ? "error"
                      : ""
                  }
                `}
                  onValueChange={({ floatValue }) => {
                    this.onChangeSettings("registrationFeeAmount", floatValue);
                  }}
                  decimalScale={2}
                  thousandSeparator={true}
                  value={registrationFeeAmount}
                  readOnly={!chabadHouseHasPaymentProviders}
                />
                <span>Leave blank for a free resource</span>

                {submitAttempted && registrationFeeAmount === 0 && (
                  <span className="small-text error-text ml-4">
                    Registration fee must be greater than 0
                  </span>
                )}
              </Grid>

              {/* TODO why don't the checkboxes accept focus from tab? */}
              <Grid item xs={12} container direction="row">
                <Checkbox
                  checked={askForDonation}
                  className="accent-text-dark event-settings-checkbox"
                  disabled={!chabadHouseHasPaymentProviders}
                  label="Request donation when people are signing up for this resource"
                  name="askForDonation"
                  onChange={this.onChangeSettings}
                />
                {!chabadHouseHasPaymentProviders && this.isShliach && (
                  <span
                    className="tooltip-container flex ml-8 mobile-full-width"
                    style={{ bottom: 4 }}
                  >
                    <i className="material-icons link-text no-hover medium-text">
                      info
                    </i>
                    <span className="tooltip">
                      In order to ask for a donation, complete the Stripe
                      account section in your Chabad House profile{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={this.goToChabadHouseActivitySettings}
                      >
                        here
                      </span>
                    </span>
                  </span>
                )}
              </Grid>

              <Grid xs={12} item container direction="row">
                <Checkbox
                  checked={isIsraelRelated}
                  className="accent-text-dark event-settings-checkbox"
                  label="Israel/Antisemitism Related"
                  name="isIsraelRelated"
                  onChange={this.onChangeSettings}
                />
                {this.isShliach && (
                  <Tooltip
                    title="Israel/Antisemitism Related will be used for student interaction
                      tracking purposes, and will not be displayed to the
                      student on the public site"
                  >
                    <Info className="ml-8 medium-text accent-text-dark" />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Box>

          <div className="program-form-section">
            <Typography variant="h6" className="mb-8">
              Resource Image
            </Typography>

            <div className="accent-text-dark mb-24 line-height-double">
              <p>This image will be displayed on the resource card</p>
              <p>
                For generic resource images click{" "}
                <a
                  className="link-text"
                  href={`/programs/${programID}?sched=${programScheduleID}#generic-images`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  here
                </a>
              </p>
              <p>
                Note that images with text may look disproportionate on
                different screen sizes
              </p>
            </div>
            <ImageUpload
              cropAspectHeight={1}
              cropAspectWidth={1.5}
              forceCrop={true}
              imageType="event_banner"
              imageURL={imageURL}
              onChange={this.onChangeSettings}
              label="Upload Image"
              name="imageURL"
              uploadedLabel="Change Image"
            />
          </div>
          <div className="program-form-section">
            <div className="mb-16">
              <p className="medium-text fw-700 mb-8">Resource Permissions</p>
              <div className="accent-text-dark line-height-double">
                Choose who can register for this resource. Resources are hidden
                from students who are restricted from registering.
              </div>
            </div>
            <div>
              <RadioGroup
                name="isPrivate"
                value={isPrivate}
                onChange={(_, val) => {
                  this.onChangeSettings("isPrivate", val === "true", {
                    accessTags: [],
                  });
                }}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label={
                    <Typography
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Public
                      <Tooltip
                        title="Registration is open to all students"
                        style={{ marginLeft: "8px" }}
                      >
                        <Info className="accent-text large-text" />
                      </Tooltip>
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label={
                    <Typography
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Private
                      <Tooltip
                        title="Registration is open to selected groups and to anyone with the resource link or SMS code"
                        style={{ marginLeft: "8px" }}
                      >
                        <Info className="accent-text large-text" />
                      </Tooltip>
                    </Typography>
                  }
                />
              </RadioGroup>
              {isPrivate && (
                <>
                  <MultiSelect
                    className="mt-16"
                    isSearchable={true}
                    isMulti={true}
                    name="accessTags"
                    onChange={(name, vals) => {
                      const tagIds = vals.map((v) => v.value);
                      this.onChangeSettings(
                        name,
                        chabadHouseTagOptions.filter((t) =>
                          tagIds.includes(t.id),
                        ),
                      );
                    }}
                    options={chabadHouseTagOptions.map((t) => ({
                      label: t.tagName,
                      value: t.id,
                    }))}
                    placeholder="Select group(s) with access"
                    value={accessTags.map((t) => ({
                      label: t.tagName,
                      value: t.id,
                    }))}
                  />
                  {chabadHouseTagsError && (
                    <p className="error-text mt-8">
                      Error retrieving student group options for access
                    </p>
                  )}
                  <div className="rsvp-private-access-note">
                    <i className="material-icons xl-text">info_outlined</i>
                    <div>
                      <Typography
                        color="primary"
                        variant="subtitle1"
                        style={{ lineHeight: 1.25, marginBottom: "6px" }}
                      >
                        How can students register for this resource?
                      </Typography>
                      <Typography variant="body2">
                        1.{" "}
                        <Typography component="span" variant="subtitle2">
                          Events page
                        </Typography>{" "}
                        - Only members of the selected groups above will see
                        this resource on their events page.
                        <br />
                        2.{" "}
                        <Typography component="span" variant="subtitle2">
                          Shareable URL
                        </Typography>{" "}
                        - All students can register via the shareable URL.
                        <br />
                        {isChabadHouseEnrolledInMessaging &&
                          !!chabadHouseMessagingNumber && (
                            <>
                              3.{" "}
                              <Typography component="span" variant="subtitle2">
                                SMS message
                              </Typography>{" "}
                              - All students can register by texting the
                              resource name/abbreviated name to{" "}
                              {chabadHouseMessagingNumber}.
                            </>
                          )}
                      </Typography>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="program-form-section">
            <div className="mb-24">
              <p className="medium-text fw-700 mb-16">
                Resource Capacity (optional)
              </p>
              <div className="flex flex-align-center mobile-block">
                <label className="accent-text-dark">Total Capacity</label>
                <input
                  className="custom-input"
                  min={0}
                  name="maxCapacity"
                  onChange={this.onChangeSettingsEvt}
                  type="number"
                  value={maxCapacity}
                />
              </div>
            </div>
          </div>

          <ReservationConfirmationMessageSettings
            overrideDefaultConfirmationMessage={
              overrideDefaultConfirmationMessage
            }
            name={name}
            rsvpItemType="resource"
            customConfirmationMessage={customConfirmationMessage}
            onChangeState={this.onChangeState}
            onChangeSettingsEvt={this.onChangeSettingsEvt}
            onChangeSettings={this.onChangeSettings}
          />
          <div className="program-form-section">
            <Typography variant="h6">Custom Questions</Typography>
            {questionsForAllResources?.length > 0 && (
              <div className="accent-text mt-10">
                You selected these questions to be asked on all resources. This
                setting can be edited on the RSVP Settings page.
                {questionsForAllResources.map((q, i) => (
                  <div key={i} className="custom-question-question mt-10">
                    {q.questionText}
                  </div>
                ))}
              </div>
            )}
            {resourceSpecificQuestions?.length > 0 && (
              <div className="accent-text mt-24">
                <div>
                  Select from previous custom questions to be asked when siging
                  up for this resource
                </div>
                <div>
                  {resourceSpecificQuestions.map((q, i) => {
                    const checked = thisResourceQuestions.some(
                      (rq) => rq.id === q.id,
                    );
                    return (
                      <div key={i} className="mt-16">
                        <Checkbox
                          checked={checked}
                          className="accent-text-dark event-settings-checkbox"
                          name={q.id}
                          label={q.questionText}
                          onChange={(e) => {
                            let questions = _cloneDeep(
                              this.state.settings.registrationQuestions,
                            );
                            if (checked) {
                              questions = questions.filter(
                                (rq) => rq.id !== q.id,
                              );
                            } else {
                              questions = [...questions, q];
                            }
                            this.onChangeSettings(
                              "registrationQuestions",
                              questions,
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="flex flex-align-center mt-16">
              Add Custom Question
              <i
                className="material-icons link-text ml-16"
                onClick={this.onAddRemoveQuestion}
              >
                add_circle
              </i>
            </div>
            <div className="accent-text mt-8 flex flex-align-center mb-16">
              These questions will be saved in RSVP general program settings and
              can be edited there.
            </div>
            {additionalQuestions?.map((question, index) => (
              <RsvpCustomQuestion
                key={index}
                question={question}
                onChange={this.onChangeSettings}
                onChangeEvent={this.onChangeSettingsEvt}
                submitAttempted={submitAttempted}
                onAddRemoveQuestion={this.onAddRemoveQuestion}
                isResource
              />
            ))}
          </div>
          {!isNew && resourceId && (
            <div className="program-form-section">
              <Typography variant="h6" className="mb-8">
                Shareable URLs
              </Typography>
              <div>
                <label className="accent-text-dark mr-24 tablet-mt-8 mobile-mt-8 line-height-double">
                  This resource's shareable URL
                </label>
                <p className="small-text accent-text mb-8">
                  Page will display this resource only
                </p>
                <CopyToClipboardTextbox
                  className="copy-to-clipboard-textbox flex flex-justify-space"
                  goToLabel="View Link"
                  id="rsvp-sched-url"
                  label="Copy Link"
                  showGoTo={true}
                  text={`${chabadHouseRsvpURL}/resources/${resourceId}`}
                />
              </div>
              <div className="mt-16">
                <label className="accent-text-dark mr-24 tablet-mt-8 mobile-mt-8 line-height-double">
                  Your Chabad House's RSVP System shareable URL
                </label>
                <p className="small-text accent-text mb-8">
                  Page will display all your Chabad House events and resources
                </p>
                <div className="full-width">
                  <CopyToClipboardTextbox
                    className="copy-to-clipboard-textbox flex flex-justify-space"
                    goToLabel="View Link"
                    id="rsvp-events-url"
                    label="Copy Link"
                    showGoTo={true}
                    text={chabadHouseRsvpURL}
                  />
                  {!!forChabadHouseID && this.isShliach && (
                    <p className="small-text accent-text-dark mt-8 line-height-double">
                      The URL Friendly Name (the part of the URL that references
                      your Chabad House) can be updated in your Chabad House
                      profile by clicking{" "}
                      <a
                        className="link-text"
                        href={`/account`} //TODO scroll to chabad house section
                      >
                        here
                      </a>
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-justify-start mb-16 mt-24">
          <Button
            disabled={loading || !hasChanges}
            variant="contained"
            onClick={this.onCancelSettingsChanges}
          >
            Cancel
          </Button>
          <Button
            className="ml-32"
            disabled={loading || !hasChanges}
            color="primary"
            variant="contained"
            onClick={() => this.onSubmitSettings(false)}
          >
            {submitActionType === this.actionTypes.submit && loading
              ? "Saving..."
              : isNew
              ? "Create Resource"
              : "Save"}
          </Button>
        </div>

        {submitAttempted &&
          submitActionType === this.actionTypes.submit &&
          !loading &&
          errorMessage && <div className="error-message">{errorMessage}</div>}

        {/* Unlimited Capacity Confirmation */}
        <ConfirmationModal
          cancel={() =>
            this.setState({ showUnlimitedCapacityConfirmationModal: false })
          }
          confirm={() => this.onSubmitSettings(true)}
          message={`${
            maxCapacity === "0"
              ? "Leaving resource capacity at 0 means you have set no capacity and"
              : "Not setting resource capacity means that"
          } this is an unlimited resource. Are you sure you would like this resource to have unlimited availability?`}
          show={showUnlimitedCapacityConfirmationModal}
          title="Unlimited Capacity Resource Confirmation"
        />
        {/* Notify Students About Event Update Prompt */}
        {showNotifyStudentsAboutResourceUpdateModal && (
          <ConfirmationModal
            cancel={() =>
              this.setState({
                showNotifyStudentsAboutResourceUpdateModal: false,
              })
            }
            cancelText="No"
            confirm={() =>
              sendResourceSmsMessage(
                resourceId,
                rsvpMessageTypes.updatedResource,
                actions,
              )
            }
            confirmText="Yes, send SMS message"
            message={
              "The resource fee has been changed. " +
              "Would you like to notify your students about the change? " +
              "(Defaulted to include all Jewish students)"
            }
            show={showNotifyStudentsAboutResourceUpdateModal}
            title="Notify Students?"
          />
        )}
      </React.Fragment>
    );
  }
}

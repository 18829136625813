import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, DialogContent, Typography } from "@material-ui/core";
import {
  BillingDialogFooterStyled,
  BillingDialogStyled,
} from "./Billing.styles";
import {
  AccountSelectors,
  EduActions,
  SystemSelectors,
  useAppDispatch,
} from "../../../../state";
import { BillingAddress, CreditCardInfo } from "../../../../components/billing";
import { getJewishUMembershipEffectiveThroughYear } from "../../eduUtils";
import { formatCurrency } from "../../../../lib";
import { injectStripe } from "react-stripe-elements";
import _cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";

export const BillingReasons = {
  PaymentMethodUpdate: "PaymentMethodUpdate",
  SubscriptionRenewal: "SubscriptionRenewal",
};

interface BillingModalProps {
  billingAmount: number;
  billingReason: string;
  enrollmentId: number;
  onClose: (refreshBilling?: boolean) => void;
  stripe?: any; // this prop is injected via injectStripe
}

function BillingModal({
  billingAmount,
  billingReason,
  enrollmentId,
  onClose,
  stripe,
}: BillingModalProps) {
  const dispatch = useAppDispatch();

  const account = useSelector(AccountSelectors.account);
  const countries = useSelector(SystemSelectors.countries);

  const [billingDetails, setBillingDetails] = useState({
    billing: {
      amountCents: billingAmount * 100,
      address: {
        address1: "",
        address2: "",
        country: "",
        city: "",
        state: "",
        zip: "",
      },
      cardHolderFullName: "",
      hasCompleteCardInfo: false,
      stripeToken: null,
      useCardOnFile:
        billingReason === BillingReasons.SubscriptionRenewal &&
        !!account.credCardInfo,
    },
    billingReason,
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const onChange = useCallback(
    (name: string, value: any, otherChanges: any = null) => {
      const _billingDetails = _cloneDeep(billingDetails);
      _set(_billingDetails, name, value);

      if (otherChanges) {
        Object.keys(otherChanges).forEach((changeName) =>
          _set(_billingDetails, changeName, otherChanges[changeName]),
        );
      }

      setBillingDetails(_billingDetails);

      if (errorMessage) {
        setErrorMessage("");
      }
    },
    [errorMessage, billingDetails],
  );

  const onChangeEvt = useCallback(
    (evt: any) => {
      onChange(evt.target.name, evt.target.value);
    },
    [onChange],
  );

  const validateBillingDetails = useCallback(() => {
    const {
      billing: {
        address: { address1, city, country },
        cardHolderFullName,
        hasCompleteCardInfo,
        useCardOnFile,
      },
    } = billingDetails;

    if (
      !useCardOnFile &&
      (!address1 ||
        !city ||
        !country ||
        !hasCompleteCardInfo ||
        !cardHolderFullName)
    ) {
      setErrorMessage("Please complete required fields");
      return false;
    }

    setErrorMessage("");
    return true;
  }, [billingDetails]);

  const onSubmit = useCallback(async () => {
    setSubmitAttempted(true);

    if (!validateBillingDetails()) {
      return;
    }

    setLoading(true);

    //get stripe token for payment method processing
    const {
      billing: { address, amountCents, cardHolderFullName, useCardOnFile },
      billingReason,
    } = billingDetails;

    if (!useCardOnFile) {
      try {
        const stripeValues = {
          name: cardHolderFullName,
          address_line1: address.address1,
          address_line2: address.address2,
          address_city: address.city,
          address_state: address.state,
          address_zip: address.zip,
          address_country: countries?.find(
            (c: any) => c.name === address.country,
          )?.code,
        };

        const { token } = await stripe.createToken(stripeValues);
        billingDetails.billing.stripeToken = token;
      } catch (err) {
        setErrorMessage("Unable to process credit card. Please try again.");
        setLoading(false);
        return;
      }
    }

    const { errorMessage, success } = await dispatch(
      EduActions.submitEduEnrollmentBilling(enrollmentId, billingDetails),
    );

    if (success) {
      const refreshBilling =
        !!amountCents && billingReason === BillingReasons.PaymentMethodUpdate;
      onClose(refreshBilling);
    } else if (errorMessage) {
      setErrorMessage(errorMessage);
      setLoading(false);
    }
  }, [
    billingDetails,
    countries,
    dispatch,
    enrollmentId,
    onClose,
    stripe,
    validateBillingDetails,
  ]);

  const membershipEffectiveThroughYear =
    getJewishUMembershipEffectiveThroughYear();

  const { billing } = billingDetails;

  return (
    <BillingDialogStyled open={true} fullWidth>
      <DialogContent>
        <Typography variant="h4" className="mb-16">
          {billingReason === BillingReasons.SubscriptionRenewal
            ? "Renew membership"
            : `${account.credCardInfo ? "Edit" : "Add"} payment method`}
        </Typography>
        {billingReason === BillingReasons.SubscriptionRenewal && (
          <Typography variant="body2" color="textSecondary" className="mb-24">
            Renew your JewishU membership to continue offering courses for{" "}
            {membershipEffectiveThroughYear - 1}-
            {membershipEffectiveThroughYear}.
          </Typography>
        )}
        {billingAmount > 0 && (
          <Typography variant="h6" className="mb-16">
            Payment - ${formatCurrency(billingAmount)}
          </Typography>
        )}
        <CreditCardInfo
          billing={billing}
          cardOnFile={
            billingReason === BillingReasons.SubscriptionRenewal
              ? account.credCardInfo
              : null
          }
          hideTitle={true}
          onChange={onChange}
          onChangeEvt={onChangeEvt}
          submitAttempted={submitAttempted}
        />
        {!billing.useCardOnFile && (
          <Box className="mt-24">
            <BillingAddress
              billing={billing}
              chabadHouseAddress={account.primaryChabadHouse?.address}
              countries={countries}
              familyAddress={account.familyAddress}
              onChange={onChange}
              onChangeEvt={onChangeEvt}
              submitAttempted={submitAttempted}
            />
          </Box>
        )}
      </DialogContent>
      <BillingDialogFooterStyled>
        <Button
          disabled={loading}
          onClick={() => onClose()}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={loading}
          onClick={onSubmit}
          variant="contained"
        >
          {billingReason === BillingReasons.SubscriptionRenewal
            ? loading
              ? "Renewing..."
              : "Renew"
            : loading
            ? "Saving..."
            : billingAmount
            ? "Save and charge"
            : "Save"}
        </Button>
        {errorMessage && (
          <Typography className="error-text">{errorMessage}</Typography>
        )}
      </BillingDialogFooterStyled>
    </BillingDialogStyled>
  );
}

export default injectStripe(memo(BillingModal));

import React, { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";

import { Navigation, InternPermissions, doArraysIntersect } from "../../lib";

import Orders from "./orders/Orders";
import Settings from "./settings/Settings";
import Metrics from "./metrics/Metrics";
import Donors from "./donors/Donors";
import Teams from "./teams/Teams";
import { RaffleSelectors, AuthSelectors } from "../../state";

//ToDo: define types instead of any
interface MyRaffleProps {
  pageRoute: any;
  errorMessage: any;
  success: boolean;
  actions: any;
  setTabContentShowing: React.Dispatch<React.SetStateAction<string>>;
  sys: any;
  shliachID: number;
  tabContentShowing: "orders" | "metrics" | "donors" | "settings" | "teams";
}

export default function MyRaffle(props: MyRaffleProps) {
  //SELECTORS
  const raffle = useSelector(RaffleSelectors.raffle);
  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const userClaims = useSelector(AuthSelectors.claims);

  const defaultTab = isShliach ? "metrics" : "orders";
  const { historicEnrollments, enrollmentDetails: { id = undefined } = {} } =
    raffle;

  const {
    pageRoute,
    errorMessage,
    success,
    actions,
    setTabContentShowing,
    tabContentShowing,
  } = props;

  const currentRaffle =
    success &&
    historicEnrollments
      .filter((enrollment: any) => enrollment.sellerEnrollmentID === id)
      .map((enrollment: any) => ({
        key: enrollment,
        value: enrollment,
        label: enrollment.raffleTitle,
      }))[0];

  let isEnrolled;

  if (currentRaffle) {
    isEnrolled =
      historicEnrollments.filter(
        (enrollment: any) =>
          enrollment.sellerEnrollmentID ===
          currentRaffle.value.sellerEnrollmentID,
      ).length > 0;
  }

  const navigate = useCallback(
    (page) => {
      setTabContentShowing(page);
      Navigation.redirect(`${pageRoute.location.pathname}?tab=${page}`);
    },
    [pageRoute.location.pathname, setTabContentShowing],
  );

  useEffect(() => {
    const tab = pageRoute.query.tab;
    if (tab === undefined) {
      navigate(defaultTab);
    } else {
      setTabContentShowing(tab);
    }
  }, [pageRoute.query.tab, navigate, setTabContentShowing, defaultTab]);

  const raffleTabComponents = [
    {
      value: "metrics",
      component: (
        <Metrics
          raffle={raffle}
          getRaffleMetrics={actions.getRaffleMetrics}
          getRaffleSales={actions.getRaffleSales}
          getRaffleSalesComparison={actions.getRaffleSalesComparison}
          getRaffleTopSellers={actions.getRaffleTopSellers}
          pageRoute={pageRoute}
          navigate={navigate}
          sys={props.sys}
        />
      ),
      internPermissions: [],
    },
    {
      value: "orders",
      component: (
        <Orders
          pageRoute={pageRoute}
          raffle={raffle}
          getRaffleOrders={actions.getRaffleOrders}
          getRaffleOrdersForExport={actions.getRaffleOrdersForExport}
          importBulkOrders={actions.importBulkOrders}
          createOrder={actions.createOrder}
          sys={props.sys}
          getRaffleEnrollment={actions.getRaffleEnrollment}
          shliachID={props.shliachID}
          resendOrderEmail={actions.resendOrderEmail}
        />
      ),
      internPermissions: [InternPermissions.manageRaffleDonations],
    },
    {
      value: "settings",
      component: <Settings />,
      internPermissions: [],
    },
    {
      value: "teams",
      component: <Teams raffle={raffle} />,
      internPermissions: [],
    },
    {
      value: "donors",
      component: (
        <Donors
          getRaffleDonors={actions.getRaffleDonors}
          getRaffleDonorDetails={actions.getRaffleDonorDetails}
          pageRoute={pageRoute}
          raffle={raffle}
        />
      ),
      internPermissions: [InternPermissions.manageRaffleDonations],
    },
  ];

  return (
    <Box>
      {success && isEnrolled ? (
        raffleTabComponents.find(
          (tab) =>
            tab.value === tabContentShowing &&
            (isShliach || doArraysIntersect(userClaims, tab.internPermissions)),
        )?.component
      ) : (
        <div className="text-center">
          <img src="/images/error.svg" alt="error-robot" height="320" />
          <p className="text-center error-message mt-24 relative">
            {errorMessage && errorMessage.status === 403
              ? "You are not authorized to enroll in this Grand Draw"
              : errorMessage &&
                errorMessage.data &&
                errorMessage.data.messages[0]
              ? errorMessage.data.messages[0]
              : "Something went wrong. Please try again."}
          </p>
        </div>
      )}
    </Box>
  );
}

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  EngagementActions,
  EngagementSelectors,
  SystemSelectors,
} from "../../../../state";
import {
  getAppliedStudentInteractionTypesAsync,
  getStudentInteractionsSummaryAsync,
} from "../../../../state/engagement/EngagementApi";

import ChooseColumnsModal from "./ChooseColumnsModal";
import PaginatedTable from "../../../../components/PaginatedTable";
import Search from "../../../../components/Search";
import ExportCSVButton from "../../../../components/ExportCSVButton";
import NoAccess from "../../../shared/NoAccess";

import {
  unspecifiedInteractionType,
  decodeSelectedInteractionTypes,
  encodeSelectedInteractionTypes,
} from "./shared";
import {
  Navigation,
  PageURL,
  getYearOptions,
  dateAddYears,
  isMobileView,
  IsApiErrorStatus,
  PageLink,
  formatFullName,
} from "../../../../lib";
import Pages from "../../../../pages";

function calculateInteractionTypesToDisplay(
  allInteractionTypes,
  includedInteractionTypes,
) {
  const includedInteractionTypesArray = decodeSelectedInteractionTypes(
    includedInteractionTypes,
  );

  const interactionTypesToDisplay = [
    ...allInteractionTypes,
    unspecifiedInteractionType,
  ];

  return interactionTypesToDisplay.filter((it) =>
    includedInteractionTypesArray.includes(it.id),
  );
}

export default function StudentInteractionsSummary(props) {
  const {
    defaultInteractionTypes,
    pageRoute,
    pageRoute: {
      page,
      query,
      query: {
        p: resultsPage = 1,
        r: resultsView = 15,
        se: searchTerm,
        rt: rangeType,
        it: interactionType,
        gy: gradYear,
        si: selectedInteractionTypes,
        so: sortBy,
      },
      params,
    },
    setDefaultInteractionTypes,
  } = props;

  // TODO this data doesn't need to be in redux
  const {
    data: {
      grandTotalInteractionsCount = 0,
      totalInteractionCountsByType = [],
      studentInteractionAggregates = [],
      numberOfRows = 0,
    },
    loading,
    error,
    success,
  } = useSelector(EngagementSelectors.studentInteractionsSummary);

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const toggleSelectedRowIndex = useCallback(
    (newIndex) => {
      newIndex === selectedRowIndex
        ? setSelectedRowIndex(null)
        : setSelectedRowIndex(newIndex);
    },
    [selectedRowIndex],
  );

  const dispatch = useDispatch();
  useEffect(
    function loadData() {
      dispatch(
        EngagementActions.getStudentInteractionsSummary(
          resultsPage,
          resultsView,
          {
            searchTerm,
            rangeType,
            interactionType,
            gradYear,
            includedInteractionTypes:
              selectedInteractionTypes || defaultInteractionTypes,
            sortBy,
          },
        ),
      );
    },
    [
      defaultInteractionTypes,
      dispatch,
      gradYear,
      interactionType,
      rangeType,
      resultsPage,
      resultsView,
      searchTerm,
      selectedInteractionTypes,
      sortBy,
    ],
  );

  const [interactionTypesToDisplay, setInteractionTypesToDisplay] = useState(
    [],
  );
  const allInteractionTypes = useSelector(SystemSelectors.interactionTypes);

  const onChangeFilters = useCallback(
    (name, val) => {
      const url = PageURL.to(page, params, {
        ...query,
        [name]: val || undefined,
        p: undefined, //reset page to 1
      });

      Navigation.redirect(url);
    },
    [page, params, query],
  );

  useEffect(
    function updateInteractionTypesToDisplay() {
      //load defaults when filter params are updated
      const loadDefaultInteractionTypes = async () => {
        const results = await getAppliedStudentInteractionTypesAsync(
          { searchTerm, rangeType, interactionType, gradYear },
          true,
        );
        if (results.data) {
          const encodedDefaultInteractionTypes = encodeSelectedInteractionTypes(
            results.data,
          );
          setDefaultInteractionTypes(encodedDefaultInteractionTypes);
        }
      };
      loadDefaultInteractionTypes();
    },
    [
      gradYear,
      rangeType,
      searchTerm,
      interactionType,
      setDefaultInteractionTypes,
    ],
  );

  useEffect(
    function updateInteractionTypesToDisplay() {
      const _interactionTypesToDisplay = calculateInteractionTypesToDisplay(
        allInteractionTypes,
        selectedInteractionTypes || defaultInteractionTypes,
      );
      setInteractionTypesToDisplay(_interactionTypesToDisplay);
    },
    [allInteractionTypes, defaultInteractionTypes, selectedInteractionTypes],
  );

  const getURLForPagination = useCallback(
    (newResultsPage, newResultsView) => {
      return PageURL.to(page, params, {
        ...query,
        p: newResultsPage,
        r: newResultsView,
      });
    },
    [page, params, query],
  );

  if (IsApiErrorStatus(error, 403)) {
    return <NoAccess systemName="the Engagement Portal" />;
  }

  return (
    <PaginatedTable
      className="interactions-summary-table"
      getURL={getURLForPagination}
      filterComponent={
        <Filters
          defaultInteractionTypes={defaultInteractionTypes}
          onChange={onChangeFilters}
          pageRoute={pageRoute}
          interactionTypesToDisplay={interactionTypesToDisplay}
        />
      }
      filterComponentClassName="interactions-summary-table-filters-container"
      isMultilineFilter
      loading={loading}
      name="Student Interactions Summary"
      page={resultsPage}
      pageRoute={pageRoute}
      records={studentInteractionAggregates}
      renderHeaderRow={() => (
        <Header
          grandTotal={grandTotalInteractionsCount}
          totalInteractionCountsByType={totalInteractionCountsByType}
          interactionTypesToDisplay={interactionTypesToDisplay}
          numberOfRows={numberOfRows}
        />
      )}
      renderRow={(studentInteractions, index) => (
        <Row
          key={index}
          studentInteractions={studentInteractions}
          interactionTypesToDisplay={interactionTypesToDisplay}
          isSelected={index === selectedRowIndex}
          onClick={() => toggleSelectedRowIndex(index)}
        />
      )}
      resultsIncrement={15}
      resultsView={resultsView}
      showResultsTotal={true}
      showResultsView={true}
      success={success}
      totalCount={numberOfRows}
    />
  );
}

function Filters({
  onChange,
  defaultInteractionTypes,
  pageRoute,
  interactionTypesToDisplay,
}) {
  const {
    query: {
      se: searchTerm = "",
      rt: rangeType = "",
      it: interactionType = "",
      gy: gradYear = "",
      so: sortBy = "",
    },
  } = pageRoute;

  const [showChooseColumnsModal, setShowChooseColumnsModal] = useState(false);

  const dateRangeTypeFilterOptions = [
    { label: "Current month", value: "currentMonth" },
    { label: "Past month", value: "pastMonth" },
    { label: "Past 3 months", value: "past3Months" },
    { label: "Past 6 months", value: "past6Months" },
    { label: "Past year", value: "pastYear" },
    { label: "Past 2 years", value: "past2Years" },
    { label: "All Time", value: "all" },
  ];

  const soOptions = [
    { label: "Last Name", value: "lastName" },
    { label: "First Name", value: "firstName" },
    { label: "Most Interactions", value: "mostInteractions" },
    { label: "Least Interactions", value: "leastInteractions" },
  ];

  const interactionTypeFilterOptions = [
    { label: "All Interaction Types", value: "" },
    ...interactionTypesToDisplay.map((it) => ({
      label: it.name,
      value: it.id,
    })),
  ];

  const gradYearFilterOptions = [
    { label: "All Grad Years", value: "" },
    ...getYearOptions(
      dateAddYears(new Date(), -15).getFullYear(),
      dateAddYears(new Date(), 15).getFullYear(),
    ).map((yr) => ({
      value: yr,
      label: yr,
    })),
  ];

  return (
    <>
      <div className="flex flex-column full-width">
        <Search
          value={decodeURIComponent(searchTerm)}
          onSearch={onChange}
          placeholder="Search student name"
        />
        <div className="flex flex-justify-space flex-align-center">
          <div className="interactions-summary-table-filters">
            <Filter
              name="rt"
              options={dateRangeTypeFilterOptions}
              value={rangeType}
              onChange={onChange}
            />
            <Filter
              name="it"
              options={interactionTypeFilterOptions}
              value={interactionType}
              onChange={onChange}
              width="250px"
            />
            <Filter
              name="gy"
              options={gradYearFilterOptions}
              value={gradYear}
              onChange={onChange}
            />
            <Filter
              label="Sort by"
              name="so"
              options={soOptions}
              value={sortBy}
              onChange={onChange}
            />
          </div>

          {/* Interaction type columns aren't displayed in mobile view so no need for a column chooser */}
          {!isMobileView() && (
            <div className="interactions-summary-table-filters">
              <p
                className="mr-16 nowrap-text link-text pointer mobile-mt-8 mobile-mb-8"
                onClick={() => setShowChooseColumnsModal(true)}
              >
                Choose columns
              </p>
            </div>
          )}
        </div>
      </div>

      {showChooseColumnsModal && (
        <ChooseColumnsModal
          onCancel={() => setShowChooseColumnsModal(false)}
          onSelect={(selections) => {
            const encodedInteractionTypes =
              encodeSelectedInteractionTypes(selections);
            onChange(
              "si",
              encodedInteractionTypes !== defaultInteractionTypes
                ? encodedInteractionTypes //default types aren't set in the URL
                : "",
            );
            setShowChooseColumnsModal(false);
          }}
          defaultInteractionTypes={defaultInteractionTypes}
          pageRoute={pageRoute}
        />
      )}
    </>
  );
}

function Filter(props) {
  const {
    label,
    name,
    options,
    value,
    onChange,
    className = "flex flex-align-center ml-16 mobile-ml-0",
    width = "204px",
  } = props;

  return (
    <div className={className} style={{ width }}>
      <p className="mr-8 nowrap-text">{label || "Filter by"}</p>
      <Select
        className="filter-select"
        classNamePrefix="filter-select"
        defaultValue={options[0]}
        isSearchable={false}
        onChange={(val) => onChange(name, val.value)}
        options={options}
        value={options.find((t) => t.value.toString() === value) || options[0]}
      />
    </div>
  );
}

function getColumnStyles(interactionTypesToDisplay) {
  const numCountColumns = interactionTypesToDisplay.length + 1; // add 1 for "Total Interactions" column
  const studentDetailsColumnWidth = 270;
  const countColumnWidth = 113;
  const gridGap = 16;
  const totalSidePadding = 32;

  return {
    gridTemplateColumns: `${studentDetailsColumnWidth}px repeat(${numCountColumns}, ${countColumnWidth}px)`,
    width: `${
      studentDetailsColumnWidth +
      countColumnWidth * numCountColumns +
      gridGap * (numCountColumns - 1) +
      totalSidePadding
    }px`,
  };
}

function getCountOfInteractionType(interactionCountsArray, interactionTypeId) {
  return (
    interactionCountsArray.filter(
      (ic) => ic.interactionTypeId === interactionTypeId,
    )[0]?.count || 0
  );
}

function Header({
  grandTotal,
  totalInteractionCountsByType = [],
  interactionTypesToDisplay,
  numberOfRows,
}) {
  if (isMobileView()) {
    return (
      <div className="interactions-summary-table-header">
        <p>Students ({numberOfRows})</p>
        <p>Total Interactions ({grandTotal})</p>
      </div>
    );
  }

  return (
    <div
      className="interactions-summary-table-header"
      style={getColumnStyles(interactionTypesToDisplay)}
    >
      <p>Students ({numberOfRows})</p>
      <p>Total Interactions ({grandTotal})</p>
      {interactionTypesToDisplay.map((type, i) => (
        <p key={i}>
          {type.name} (
          {getCountOfInteractionType(totalInteractionCountsByType, type.id)})
        </p>
      ))}
    </div>
  );
}

function Row({
  studentInteractions,
  interactionTypesToDisplay,
  isSelected,
  onClick,
}) {
  const getStudentDetails = () => {
    const {
      firstName,
      lastName,
      cell,
      email,
      campusName,
      graduationYear,
      studentId,
    } = studentInteractions;

    if (!isSelected) {
      return (
        <p>
          {firstName} {lastName}
        </p>
      );
    }

    return (
      <div>
        <PageLink
          className="link-text fw-600"
          params={{ studentId }}
          query={{ studentTab: "profile" }}
          to={Pages.engagement.studentDetails}
        >
          {formatFullName(firstName, lastName)}
        </PageLink>
        <p className="accent-text">
          {cell}
          {cell && email && " | "}
          {email}
        </p>
        {campusName && <p className="accent-text">{campusName}</p>}
        {graduationYear && (
          <p className="accent-text">Graduates {graduationYear}</p>
        )}
      </div>
    );
  };

  const { interactionCountsByType = [] } = studentInteractions;

  if (isMobileView()) {
    return (
      <div
        className={`interactions-summary-table-row ${
          isSelected ? "selected" : ""
        }`}
        onClick={onClick}
      >
        {getStudentDetails()}
        <p>{studentInteractions.totalInteractionsCount}</p>
      </div>
    );
  }

  return (
    <div
      className={`interactions-summary-table-row ${
        isSelected ? "selected" : ""
      }`}
      style={getColumnStyles(interactionTypesToDisplay)}
      onClick={onClick}
    >
      {getStudentDetails()}
      <p>{studentInteractions.totalInteractionsCount}</p>
      {interactionTypesToDisplay.map((type, i) => (
        <p key={i}>
          {getCountOfInteractionType(interactionCountsByType, type.id)}
        </p>
      ))}
    </div>
  );
}

export function StudentInteractionsSummaryExportButton({
  defaultInteractionTypes,
  pageRoute,
}) {
  const {
    query: {
      p: resultsPage = 1,
      r: resultsView = 15,
      se: searchTerm,
      rt: rangeType,
      it: interactionType,
      gy: gradYear,
      si: selectedInteractionTypes,
    },
  } = pageRoute;

  const loadData = async () => {
    const results = await getStudentInteractionsSummaryAsync(
      resultsPage,
      resultsView,
      {
        searchTerm,
        rangeType,
        interactionType,
        includedInteractionTypes:
          selectedInteractionTypes || defaultInteractionTypes,
        gradYear,
      },
      true,
    );
    return results.data.studentInteractionAggregates;
  };

  const allInteractionTypes = useSelector(SystemSelectors.interactionTypes);
  const interactionTypesToDisplay = calculateInteractionTypesToDisplay(
    allInteractionTypes,
    selectedInteractionTypes || defaultInteractionTypes,
  );

  return (
    <ExportCSVButton
      disabled={false}
      className="btn btn-secondary students-export-btn"
      fileName="Student_Interactions_Summary"
      getExportData={loadData}
      getHeaders={() => [
        { label: "Student ID", value: "studentId" },
        { label: "First Name", value: "firstName" },
        { label: "Last Name", value: "lastName" },
        { label: "Email", value: "email" },
        { label: "Cell", value: "cell" },
        { label: "Campus Name", value: "campusName" },
        { label: "Grad Year", value: "gradYear" },
        { label: "Total Interactions", value: "totalInteractionsCount" },
        ...interactionTypesToDisplay.map((it) => ({
          label: it.name,
          value: ({ interactionCountsByType }) =>
            getCountOfInteractionType(interactionCountsByType, it.id),
        })),
      ]}
    />
  );
}

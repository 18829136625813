import { AccountState } from "./account/state";
import { ActivityState } from "./activity/state";
import { AuthState } from "./auth/state";
import { CampusesState } from "./campuses/state";
import { ChabadHousesState } from "./chabadHouses/state";
import { ContactState } from "./contact/state";
import { ContactsState } from "./contacts/state";
import { EduState } from "./edu/state";
import { EngagementState } from "./engagement/state";
import { KinusState } from "./kinus/state";
import { LamplightersState } from "./lamplighters/state";
import { LifeInsuranceState } from "./lifeInsurance/state";
import { LoginState } from "./login/state";
import { ProfileState } from "./profile/state";
import { ProgramState } from "./program/state";
import { ProgramsState } from "./programs/state";
import { ProgramScheduleState } from "./programSchedule/state";
import { RaffleState } from "./raffle/state";
import { ResetPassState } from "./resetPass/state";
import { RsvpState } from "./rsvp/state";
import { SchoolsState } from "./schools/state";
import { StudentsState } from "./students/state";
import { SysState } from "./sys/state";
import { TripState } from "./trip/state";
import { UIState } from "./ui/state";
import { UpdatesState } from "./updates/state";
import { UpdateState } from "./update/state";
import { SmsState } from "./sms/state";
import { AppStateInfo } from "./types";

//*** ACTIONS ***
export * from "./account/actions";
export * from "./activity/actions";
export * from "./auth/actions";
export * from "./campuses/actions";
export * from "./chabadHouses/actions";
export * from "./contact/actions";
export * from "./contacts/actions";
export * from "./edu/actions";
export * from "./engagement/actions";
export * from "./kinus/actions";
export * from "./lamplighters/actions";
export * from "./lifeInsurance/actions";
export * from "./programSchedule/actions";
export * from "./profile/actions";
export * from "./raffle/actions";
export * from "./rsvp/actions";
export * from "./students/actions";
export * from "./sys/actions";
export * from "./trip/actions";
export * from "./updates/actions";
export * from "./update/actions";
export * from "./sms/actions";
export * from "./edu/selectors";
export * from "./programs/selector";

//*** SELECTORS ***
export * from "./account/selectors";
export * from "./activity/selectors";
export * from "./auth/selectors";
export * from "./edu/selectors";
export * from "./engagement/selectors";
export * from "./lamplighters/selectors";
export * from "./raffle/selectors";
export * from "./students/selectors";
export * from "./sys/selectors";
export * from "./update/selectors";

const states: AppStateInfo[] = [
  AccountState,
  ActivityState,
  AuthState,
  CampusesState,
  ChabadHousesState,
  ContactState,
  ContactsState,
  EduState,
  EngagementState,
  KinusState,
  LamplightersState,
  LifeInsuranceState,
  LoginState,
  ProfileState,
  ProgramState,
  ProgramsState,
  ProgramScheduleState,
  RaffleState,
  ResetPassState,
  RsvpState,
  SchoolsState,
  StudentsState,
  SysState,
  TripState,
  UIState,
  UpdatesState,
  UpdateState,
  SmsState,
];

export default states;

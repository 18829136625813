import React, { useEffect, useState } from "react";
import { Collapse } from "@material-ui/core";
import CustomSelect from "../../../components/form/CustomSelect";
import TextField from "../../../components/form/MuiTextField";
import { isPositiveIntBelowMax, pluralizeText } from "../../../lib";

export default function TicketOptionsSection(props) {
  const {
    defaultTicketOption,
    ticketOptions = [],
    handleChange,
    handleBulkChange,
    isInvalid,
    setIsInvalid,
    submitAttempted,
    showAllExpanded,
  } = props;

  const ticketMax = 24999; //based on website processing limits
  const [showExpanded, setShowExpanded] = useState(showAllExpanded);
  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };

  useEffect(() => {
    setShowExpanded(showAllExpanded);
  }, [showAllExpanded]);

  useEffect(
    function validateTicketOptions() {
      const _isInvalid = ticketOptions.some(
        (option) =>
          !isPositiveIntBelowMax(option, ticketMax) ||
          isTicketEntryDuplicate(option),
      );
      setIsInvalid(_isInvalid);
    },
    // eslint-disable-next-line
    [ticketOptions],
  );

  useEffect(
    function clearDefaultOptionWhenInvalid() {
      if (isInvalid) {
        //clear default selection here bec disable happens too fast for field
        //to get cleared onChangeTicketOption or in validateTicketOptions
        handleChange("defaultTicketOption", undefined);
      }
    },
    // eslint-disable-next-line
    [isInvalid],
  );

  const onChangeTicketOption = (index, value) => {
    const _ticketOptions = [...ticketOptions];
    _ticketOptions[index] = Number(value);

    const changedSettings = {
      ticketOptions: _ticketOptions,
      defaultTicketOption: undefined, //to avoid invalid selected value
    };
    handleBulkChange(changedSettings);
  };

  const isTicketEntryDuplicate = (value) => {
    return (
      !!value && ticketOptions.filter((option) => option === value).length > 1
    );
  };

  return (
    <div className="raffles-form-section">
      <div className="raffles-section-title" onClick={handleToggleExpand}>
        <div className="flex">
          <p className="large-text fw-900 flex flex-align-center pointer mb-8">
            Ticket Options
          </p>
        </div>
        {showExpanded ? (
          <i className="material-icons accent-text-secondary xl-text">remove</i>
        ) : (
          <i className="material-icons accent-text-secondary xl-text">add</i>
        )}
      </div>

      <Collapse in={showExpanded}>
        <div className="expanded-section">
          <p className="medium-text fw-700 mb-8 mt-24">
            Ticket package options
          </p>
          <p className="accent-text-dark mb-16 line-height-double">
            Choose the ticket package quantities that will be available for
            purchase on your Grand Draw page.
          </p>
          <div className="raffle-ticket-options-grid">
            {ticketOptions.map((option, index) => (
              <div key={index} className="flex flex-align-center mb-24">
                <p className="fs-13 accent-text mr-32">Package {index + 1}</p>
                <TextField
                  className="raffle-ticket-option-input"
                  id={`${option}_${index}`}
                  variant="outlined"
                  size="small"
                  type="number"
                  value={option || ""}
                  onChange={(e) => {
                    onChangeTicketOption(index, e.target.value);
                  }}
                  error={
                    !isPositiveIntBelowMax(option, ticketMax) ||
                    isTicketEntryDuplicate(option)
                  }
                  helperText={
                    !isPositiveIntBelowMax(option, ticketMax)
                      ? "Invalid entry"
                      : isTicketEntryDuplicate(option)
                      ? "Duplicate entry"
                      : ""
                  }
                />
                <p className="fs-13 accent-text ml-8">Tickets</p>
              </div>
            ))}
          </div>
          <p className="medium-text fw-700 mb-8">
            Default selected ticket package
          </p>
          <p className="accent-text-dark mb-16 line-height-double">
            Choose the ticket package that will be selected by default when
            donors access your Grand Draw page.
          </p>
          <div className="flex flex-align-center tooltip-container">
            <p className="fs-13 accent-text mr-32">Tickets</p>
            <CustomSelect
              className={`raffle-ticket-option-select ${
                submitAttempted && !defaultTicketOption && "error"
              }`}
              name="defaultTicketOption"
              options={ticketOptions.map((option, index) => ({
                key: index,
                value: option,
                label: `${option} ${pluralizeText("Ticket", option)}`,
              }))}
              onChange={handleChange}
              value={defaultTicketOption || ""}
              disabled={isInvalid}
            />
            {isInvalid && (
              <span className="tooltip">
                Default can only be selected when all ticket packages are valid.
              </span>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

import { AppState } from "../types";
import { AccountActions } from "./actions";

interface AccountData {
  credCardInfo?: any;
  familyAddress?: any;
  homePhone?: any;
  primaryChabadHouse?: any;
  wife?: any;
  loading?: boolean;
  success?: boolean;
}

export const AccountState = {
  name: "account",
  persist: false,
  defaults: {
    loading: false,
  } as AccountData,
  handlers: {
    [AccountActions.SET_ACCOUNT_LOADING]: function setAccountLoading(
      state: AppState | any,
      action: any,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [AccountActions.UPDATE_ACCOUNT]: (state: AppState | any, action: any) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

import React, { useCallback, useState } from "react";
import TourAlertsModal from "./TourAlertsModal";
import CustomCheckbox from "../../../../components/form/CustomCheckbox";
import Radio from "../../../../components/form/Radio";
import TextField from "../../../../components/form/MuiTextField";
import { hasValue, isMobileView, isPositiveIntBelowMax } from "../../../../lib";
import { MenuItem, FormControl, Select } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import TourOptOutStudentsModal from "./TourOptOutStudentsModal";
import { TripSelectors } from "../../../../state/trip/selectors";
import { useSelector } from "react-redux";

const enrollmentOptions = {
  optionalEnroll: "OptionalEnroll",
  autoEnroll: "AutoEnroll",
  noEnroll: "NoEnroll",
};
const formatTourDateTime = (date) => {
  date = new Date(date);
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const day = weekday[date.getDay()];
  date = date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return day + ", " + date;
};

function Tours(props) {
  const {
    event: {
      tours,
      studentRegistrationStartDate,
      studentRegistrationEndDate,
      tripEndDate,
    },
    enrollmentSettings: { tours: enrolledTours, attendees },
    initialTours = [],
    navigate,
    onChange,
    onChangeTourEnrollment,
    selectedTab,
    hasEnrollment,
    validation,
    tourTransportationOptions,
    ohelTourTransportationOptions,
    optOutWarningTourIDs = [],
    setOptOutWarning,
  } = props;

  const [showToursInfo, setShowToursInfo] = useState(
    isMobileView() && hasEnrollment ? false : true,
  );
  const [showAlertsModal, setShowAlertsModal] = useState(false);
  const [showStudentRegistrationWarning, setShowStudentRegistrationWarning] =
    useState(false);
  const [showOptOutStudentsModal, setShowOptOutStudentsModal] = useState(false);
  const [optInWarningTourIDs, setOptInWarningTourIDs] = useState([]);
  const [autoEnrollWarningTourIDs, setAutoEnrollWarningTourIDs] = useState([]);
  const [optedOutTour, setOptedOutTour] = useState({});
  const registrations = useSelector(TripSelectors.registrations);
  const hasRegistrations = !!registrations.length;

  const getTourScheduleOptions = useCallback(
    (tour) => {
      const options = [];
      tour.schedules.map((schedule) => {
        // disable schedule if there are no slots left,
        // and initial tours doesn't have this schedule selected
        // warn if low on space
        const spaceIsLimited = schedule.maximumParticipants !== null;
        const noSpace = spaceIsLimited && schedule.numberOfSlotsLeft === 0;
        const lowSpace =
          spaceIsLimited && !noSpace && schedule.numberOfSlotsLeft < 30;
        const disableSchedule =
          noSpace &&
          !initialTours
            .find((t) => t.tourID === tour.id)
            ?.tourScheduleIDs?.includes(schedule.id);

        return options.push({
          value: schedule.id,
          disabled: disableSchedule,
          displayBold: schedule.name,
          display: (
            <div className="flex fs-14 no-wrap mobile-flex-column">
              {`${formatTourDateTime(schedule.time)} - ${new Date(
                schedule.calculatedEndTime,
              ).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}`}
              {disableSchedule && (
                <p className="slot-text none">Insufficient space</p>
              )}
              {lowSpace && (
                <p className="slot-text low">
                  Only {schedule.numberOfSlotsLeft} slots left
                </p>
              )}
            </div>
          ),
        });
      });
      return options;
    },
    [initialTours],
  );

  const getTourTransportationOptions = useCallback(
    (tour) => {
      if (!tour) return;
      const transportationOptions = [];

      if (tour.isOhelVisit) {
        const dropdownNote = (
          <div key="dropdown-note" className="transportation-dropdown-note">
            Note: Airport transportation is not confirmed. Once we <br />
            finalize, we'll reach out to you. We suggest you opt for <br />
            Uber if you have a small group.
          </div>
        );
        transportationOptions.push(dropdownNote);

        ohelTourTransportationOptions.forEach((option) => {
          const menuItem = (
            <MenuItem
              key={`${option.intValue}-ohel-tour`}
              value={option.enumValue}
            >
              {option.displayValue}
            </MenuItem>
          );
          transportationOptions.push(menuItem);
        });
      } else {
        tourTransportationOptions.forEach((option) => {
          const menuItem = (
            <MenuItem key={`${option.intValue}-tour`} value={option.enumValue}>
              {option.displayValue}
            </MenuItem>
          );
          transportationOptions.push(menuItem);
        });
      }
      return transportationOptions;
    },
    [tourTransportationOptions, ohelTourTransportationOptions],
  );

  const toggleTourAlertsModal = useCallback(
    (value) => {
      setShowAlertsModal(!showAlertsModal);

      if (value === false) {
        setShowStudentRegistrationWarning(false);
      }
    },
    [showAlertsModal, setShowAlertsModal, setShowStudentRegistrationWarning],
  );

  const onChangeTourSchedules = useCallback(
    async (scheduleId, enrolledTourIndex, isOhel) => {
      let enrolledTourScheduleIDs = [
        ...enrolledTours[enrolledTourIndex].tourScheduleIDs,
      ];
      const toggleOff = enrolledTourScheduleIDs.indexOf(scheduleId) >= 0;

      if (toggleOff) {
        enrolledTourScheduleIDs = enrolledTourScheduleIDs.filter(
          (id) => id !== scheduleId,
        );
      } else {
        if (isOhel) {
          enrolledTourScheduleIDs = [scheduleId];
        } else {
          enrolledTourScheduleIDs.push(scheduleId);
        }
      }
      const newSchedules = tours[enrolledTourIndex].schedules.filter((s) =>
        enrolledTourScheduleIDs.includes(s.id),
      );
      enrolledTours[enrolledTourIndex].schedules = newSchedules;
      enrolledTours[enrolledTourIndex].tourScheduleIDs =
        enrolledTourScheduleIDs;

      await onChange("tours", enrolledTours);

      //if student registration is open then set student registration warning to true
      if (
        new Date(studentRegistrationStartDate) <= new Date() &&
        hasEnrollment
      ) {
        setShowStudentRegistrationWarning(true);
      }
      if (showStudentRegistrationWarning) {
        toggleTourAlertsModal(true);
      }
    },
    [
      enrolledTours,
      hasEnrollment,
      onChange,
      studentRegistrationStartDate,
      showStudentRegistrationWarning,
      toggleTourAlertsModal,
      tours,
    ],
  );

  const getScheduleErrorClassName = (enrolledTour = {}) => {
    const tourId = enrolledTour.id || enrolledTour.tourID;

    if (
      (validation.tours && enrolledTour.tourScheduleIDs < 1) ||
      validation.overlappingSchedules?.includes(tourId)
    ) {
      return "error";
    }
    return "";
  };

  const getTransportationErrorClassName = (enrolledTour = {}) => {
    if (
      validation.tours &&
      enrolledTour.tourID &&
      !enrolledTour.transportationOption
    ) {
      return "error error-text";
    }
    return "";
  };

  const isValidNumberOfChaperones = useCallback(
    (enrolledTour) => {
      // valid if not enrolled or didn't attempt submission yet
      if (
        !enrolledTour ||
        (enrolledTour.numberOfChaperones === undefined && !validation.tours)
      )
        return true;

      // invalid if attempted submission and no value
      if (validation.tours && !hasValue(enrolledTour.numberOfChaperones))
        return false;

      return isPositiveIntBelowMax(
        enrolledTour?.numberOfChaperones,
        attendees.length,
        true,
      );
    },
    [validation.tours, attendees],
  );

  const setOptInWarning = useCallback(
    (optInValue, tourID) => {
      if (!optInValue) {
        setOptInWarningTourIDs([
          ...optInWarningTourIDs.filter((id) => id !== tourID),
        ]);
      } else if (!initialTours.find((t) => t.tourID === tourID)) {
        setOptInWarningTourIDs([...optInWarningTourIDs, tourID]);
      }
    },
    [initialTours, optInWarningTourIDs],
  );

  const setAutoEnrollWarning = useCallback(
    (autoEnrollValue, tourID) => {
      if (!autoEnrollValue) {
        setAutoEnrollWarningTourIDs([
          ...autoEnrollWarningTourIDs.filter((id) => id !== tourID),
        ]);
      } else if (
        !initialTours.find(
          (t) => t.tourID === tourID && t.autoEnrollStudentsOverride,
        )
      ) {
        setAutoEnrollWarningTourIDs([...autoEnrollWarningTourIDs, tourID]);
      }
    },
    [initialTours, autoEnrollWarningTourIDs],
  );
  const onChangeEnrollmentValue = useCallback(
    (value, tour) => {
      const optInValue = value === enrollmentOptions.noEnroll ? false : true;
      const autoEnrollValue =
        value === enrollmentOptions.autoEnroll ? true : false;

      onChangeTourEnrollment(optInValue, {
        ...tour,
        autoEnrollStudentsOverride: optInValue ? autoEnrollValue : null,
      });
      if (hasRegistrations) {
        //Set warnings for students who have already registered
        setOptInWarning(optInValue, tour.id);
        setOptOutWarning && setOptOutWarning(optInValue, tour.id);
        setAutoEnrollWarning(optInValue ? autoEnrollValue : false, tour.id);
      }
    },
    [
      hasRegistrations,
      onChangeTourEnrollment,
      setAutoEnrollWarning,
      setOptInWarning,
      setOptOutWarning,
    ],
  );
  const getEnrollmentValue = useCallback(
    (enrolledTour, index) => {
      return enrolledTour
        ? tours[index].autoEnrollStudents ||
          enrolledTour.autoEnrollStudentsOverride
          ? enrollmentOptions.autoEnroll
          : enrollmentOptions.optionalEnroll
        : enrollmentOptions.noEnroll;
    },
    [tours],
  );

  return (
    <div>
      {(!hasEnrollment || isMobileView()) && (
        <div className="trip-page-form-section">
          <p
            className="xxl-text fw-500"
            onClick={
              hasEnrollment && isMobileView()
                ? selectedTab === "tours"
                  ? () => setShowToursInfo(!showToursInfo)
                  : () => {
                      navigate("tours");
                      setShowToursInfo(true);
                    }
                : null
            }
          >
            Tours
          </p>
        </div>
      )}

      {showToursInfo && !tours?.length ? (
        <p
          className="trip-page-form-section accent-text-dark no-tours-label"
          style={{ paddingBottom: "16px" }}
        >
          No Tours
        </p>
      ) : (
        showToursInfo && (
          <React.Fragment>
            {tours?.map((tour, index) => {
              const enrolledTourIndex = enrolledTours.findIndex(
                ({ tourID }) => tourID === tour.id,
              );
              const enrolledTour = enrolledTours[enrolledTourIndex];
              const disableOptOutOfTourWithFee =
                tour.price > 0 &&
                new Date() > new Date(studentRegistrationEndDate);
              const disableOptOutOfTourWithoutFee =
                new Date() > new Date(tripEndDate);

              return (
                <React.Fragment key={tour.id}>
                  <div className="trip-page-form-section">
                    <p className="medium-text fw-700 mb-16">{tour.name}</p>
                    <p className="accent-text-dark mb-24 line-height-double pre-wrap">
                      {tour.shluchimDescription}
                    </p>
                    <div>
                      <div>
                        <p className="trip-tours-form-label mb-16 flex">
                          Offer to my group
                          <span>
                            {tours[index].autoEnrollStudents !== true && (
                              <div className="tooltip-container ml-8">
                                <i className="material-icons accent-text-dark profile-add-icon">
                                  info
                                </i>
                                <span className="tooltip">
                                  Choose to offer to your group with either automatic or optional registration.
                                </span>
                              </div>
                            )}
                          </span>
                        </p>
                        <Radio
                          disabled={
                            disableOptOutOfTourWithFee ||
                            disableOptOutOfTourWithoutFee
                          }
                          name={`enrollment-tour-${index}`}
                          onChange={(_, value) =>
                            onChangeEnrollmentValue(value, tour)
                          }
                          options={[
                            {
                              display: "Yes, with optional registration",
                              value: enrollmentOptions.optionalEnroll,
                              disabled:
                                tours[index].autoEnrollStudents === true,
                              tag: tours[index].autoEnrollStudents ===
                                true &&
                                !disableOptOutOfTourWithFee &&
                                !disableOptOutOfTourWithoutFee && (
                                  <span className="tooltip-container">
                                    <i className="material-icons accent-text-dark profile-add-icon">
                                      lock
                                    </i>
                                    <div className="tooltip ml-32">
                                      This tour does not allow optional
                                      registration
                                    </div>
                                  </span>
                                ),
                            },
                            {
                              display: "Yes, force all my students to register",
                              value: enrollmentOptions.autoEnroll,
                            },
                            {
                              display: "No",
                              value: enrollmentOptions.noEnroll,
                            },
                          ]}
                          value={getEnrollmentValue(enrolledTour, index)}
                        />
                        {disableOptOutOfTourWithoutFee ? (
                          <div className="tooltip">
                            Cannot opt in/out of tours once the trip has
                            started.
                          </div>
                        ) : (
                          disableOptOutOfTourWithFee && (
                            <div className="tooltip">
                              Cannot opt in/out of this tour after student
                              registration deadline.
                            </div>
                          )
                        )}
                        {optInWarningTourIDs.includes(tour.id) && (
                          <div className="tours-warning mobile-error">
                            *Students who are already registered will not be
                            enrolled.
                          </div>
                        )}
                      </div>
                      {optOutWarningTourIDs.includes(tour.id) && (
                        <div className="tour-opt-out-warning-msg">
                          <ErrorOutlineOutlinedIcon /> You have opted out of{" "}
                          {tour.name}. Any students that are already registered
                          for the tour will be removed and refunded.
                          <span
                            className="link-text"
                            onClick={() => {
                              setShowOptOutStudentsModal(true);
                              setOptedOutTour(tour);
                            }}
                          >
                            {" "}
                            View Students
                          </span>
                        </div>
                      )}
                      <div className="flex flex-align-center mb-16 mobile-block">
                        <div className="flex flex-align-center full-width">
                          <div className="flex flex-justify-space mobile-flex-column full-width">
                            {enrolledTour &&
                              autoEnrollWarningTourIDs.includes(tour.id) && (
                                <div className="tours-warning mobile-error">
                                  *Auto-enroll has been enabled. This will
                                  affect future registrations only.
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="flex mb-16 mobile-block">
                        <p
                          className={`trip-tours-form-label mt-8 ${
                            !isValidNumberOfChaperones(enrolledTour) && "error"
                          }`}
                        >
                          Total Shluchim/Chaperones Attending
                        </p>
                        <div className="flex flex-justify-space mobile-flex-column full-width">
                          <TextField
                            className="trip-tours-form-numeric-input"
                            id="numberOfChaperones"
                            variant="outlined"
                            size="small"
                            type="number"
                            disabled={!enrolledTour}
                            value={
                              enrolledTour?.numberOfChaperones !== undefined
                                ? enrolledTour.numberOfChaperones
                                : ""
                            }
                            onChange={(e) => {
                              onChange(
                                `tours[${enrolledTourIndex}].numberOfChaperones`,
                                e.target.value,
                              );
                            }}
                            error={!isValidNumberOfChaperones(enrolledTour)}
                          />
                          {Number(enrolledTour?.numberOfChaperones) >
                            attendees.length && (
                            <div className="error-text tours-error mobile-error">
                              *You selected more chaperones attending this tour
                              than the amount of chaperones registered. Please
                              add additional chaperones or lower the number of
                              chaperones attending the tour.
                            </div>
                          )}
                        </div>
                      </div>
                      {(tour.hasTransportation ||
                        (enrolledTour &&
                          enrolledTour.transportationOption)) && (
                        <div className="flex flex-align-center mb-16 mobile-block">
                          <div className="flex flex-align-center trip-tours-form-label">
                            <p
                              className={getTransportationErrorClassName(
                                enrolledTour,
                              )}
                            >
                              Transportation
                            </p>
                            {enrolledTour && (
                              <div className="tooltip-container ml-8">
                                <i className="material-icons accent-text-dark profile-add-icon">
                                  info
                                </i>
                                <span className="tooltip">
                                  Select if your group will require
                                  transportation.
                                  {enrolledTour.isOhelVisit && (
                                    <div>
                                      <br />
                                      Note: Airport transportation is not
                                      confirmed. Once we <br />
                                      finalize, we'll reach out to you. We
                                      suggest you opt for <br />
                                      Uber if you have a small group.
                                    </div>
                                  )}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="transportation-dropdown">
                            <FormControl variant="outlined">
                              <Select
                                id={`transportation-tour-${index}`}
                                value={enrolledTour?.transportationOption || ""}
                                error={
                                  !!getTransportationErrorClassName(
                                    enrolledTour,
                                  )
                                }
                                disabled={!enrolledTour}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                onChange={(e) =>
                                  onChange(
                                    `tours[${enrolledTourIndex}].transportationOption`,
                                    e.target.value,
                                  )
                                }
                              >
                                {getTourTransportationOptions(enrolledTour)}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )}

                      <div className="flex mobile-block trip-page-tour-schedule">
                        <p
                          className={`trip-tours-form-label trip-tours-schedule-label ${getScheduleErrorClassName(
                            enrolledTour,
                          )}`}
                        >
                          Select Schedule
                        </p>
                        <div>
                          <CustomCheckbox
                            className={getScheduleErrorClassName(enrolledTour)}
                            disabled={!enrolledTour}
                            index={index}
                            name={`tours[${enrolledTourIndex}].tourScheduleIDs`}
                            onChange={(_, value) =>
                              onChangeTourSchedules(
                                value,
                                enrolledTourIndex,
                                tours[index].isOhelVisit ? true : false,
                              )
                            }
                            options={getTourScheduleOptions(tour)}
                            values={
                              (enrolledTour && enrolledTour.tourScheduleIDs) ||
                              []
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            {showAlertsModal && (
              <TourAlertsModal
                show={showAlertsModal}
                studentRegistrationWarning={showStudentRegistrationWarning}
                toggleTourAlertsModal={toggleTourAlertsModal}
              />
            )}
            {showOptOutStudentsModal && (
              <TourOptOutStudentsModal
                tour={optedOutTour}
                onClose={() => {
                  setShowOptOutStudentsModal(false);
                  setOptedOutTour({});
                }}
              />
            )}
          </React.Fragment>
        )
      )}
    </div>
  );
}
export default React.memo(Tours);

import { AppState } from "../types";

export const AccountSelectors = {
  account(state: AppState) {
    return state.account;
  },
  credCardInfo(state: AppState) {
    return state.account.credCardInfo;
  },
};

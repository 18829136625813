export const EduProgramTypes = {
  JewishU: "JewishU",
  SinaiScholars: "SinaiScholars",
  YourIsrael: "YourIsrael",
};

export const getEduProgramLogoSrc = (programType, mimeType = ".svg") =>
  programType && `/images/${programType.toLowerCase()}-logo${mimeType}`;

export const jewishUMembershipExpirationDate = "July 31st";
export const jewishUMembershipStartDate = "August 1st";

// This method gets the membership 'effective through year' for the following,
// (relying on Membership renewal date being May 1st and Membership expiration July 31st end of day):
// New enrollment - Any time
// Expired membership - Any time
// Expiring membership - May through July
// Active membership - Before May and after July
export const getJewishUMembershipEffectiveThroughYear = () => {
  const currentMonth = new Date().getUTCMonth();
  const currentYear = new Date().getUTCFullYear();

  return currentMonth < 4
    ? currentYear // subscription before May 1st is effective through the current membership year
    : currentYear + 1; // otherwise effective through the following membership year
};

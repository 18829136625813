import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Box,
  InputLabel,
} from "@material-ui/core";
import _cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";
import Big from "big.js";
import NumberFormat from "react-number-format";

import { injectStripe } from "react-stripe-elements";
import { CreditCardInfo } from "../../components/billing";
import { DialogHeader } from "../../components";

class SmsAddCreditModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      billing: {
        amount: 25,
        address: {
          address1: "",
          address2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        cardHolderFullName: "",
        currencyCode: "USD",
        useCardOnFile: false,
      },
      errorMessage: "",
      loading: false,
      submitAttempted: false,
      uploadProgress: 0,
    };
    this.state.initialState = _cloneDeep(this.state);
  }
  componentDidMount() {
    this.getAccountInfo();
  }
  getAccountInfo = async () => {
    this.setState({ loading: true });
    const {
      actions: { getAccountInfo },
      auth,
    } = this.props;
    await getAccountInfo(auth.shliachID);

    this.setState({
      loading: false,
      billing: {
        ...this.state.billing,
        useCardOnFile: !!this.props.account.credCardInfo,
      },
    });
  };

  onClose = () => {
    this.setState({
      ...this.state.initialState,
      billing: {
        ...this.state.billing,
        amount: 25,
      },
    });
    this.props.onClose();
  };

  onChange = (name, val) => {
    let newState = { ..._cloneDeep(this.state), submitAttempted: false };
    _set(newState, name, val);
    this.setState(newState);
  };
  onChangeEvt = (e) => {
    const {
      target: { name, value },
    } = e;
    this.onChange(name, value);
  };

  onSubmitPayment = async (isDigitalPay = false, digitalPayToken = null) => {
    this.setState({
      errorMessage: "",
      submitAttempted: true,
      loading: true,
    });

    const {
      billing: { address, amount, cardHolderFullName, useCardOnFile },
    } = this.state;
    if (amount <= 0) {
      this.setState({
        errorMessage: "Please enter a valid amount",
      });
      return;
    }
    let amountCents = Big(amount).times(100);

    const {
      stripe,
      systemCountries,
      chabadHouseID,
      statement: { data: { balance } = {} } = {},
      onPaymentComplete,
    } = this.props;

    let token;

    if (!useCardOnFile) {
      const stripeValues = {
        name: cardHolderFullName,
        address_line1: address.address1,
        address_line2: address.address2,
        address_city: address.city,
        address_state: address.state,
        address_zip: address.zip,
        address_country: (
          systemCountries?.find(
            (country) => country.name === address.country,
          ) || {}
        ).code,
      };

      const stripeToken = await stripe.createToken(stripeValues);
      token = stripeToken.token;
    }

    if (!useCardOnFile && !token) {
      this.setState({
        errorMessage:
          "Unable to process credit card payment.  Please try again.",
        loading: false,
      });
      return;
    }

    await this.props.actions.submitSmsCredit(
      {
        billing: { ...this.state.billing, amountCents, stripeToken: token },
      },
      chabadHouseID,
    );
    const {
      submitSmsCredit: { errorMessage },
    } = this.props;
    if (errorMessage) {
      this.setState({ errorMessage, loading: false });
      return;
    }

    this.setState({ loading: false });
    onPaymentComplete && onPaymentComplete(balance);
    this.onClose();
  };

  render() {
    const {
      account: { credCardInfo },
      show,
    } = this.props;

    const { errorMessage, billing, loading, submitAttempted } = this.state;

    return (
      <Dialog open={show} maxWidth="sm" fullWidth onClose={this.onClose}>
        <DialogHeader title="Add Credit" handleClose={this.onClose} />

        <DialogContent>
          <Box className="flex mb-24 flex-align-center">
            <InputLabel className="mr-8">Credit amount</InputLabel>
            <NumberFormat
              allowNegative={false}
              className="dollar-input custom-input"
              placeholder="Amount"
              name="billing.amount"
              value={billing.amount}
              onChange={this.onChangeEvt}
              decimalScale={2}
              thousandSeparator={true}
            />
          </Box>

          <CreditCardInfo
            billing={billing}
            cardOnFile={credCardInfo}
            onChange={this.onChange}
            onChangeEvt={this.onChangeEvt}
            submitAttempted={submitAttempted}
          />
          {submitAttempted && errorMessage && (
            <p className="small-text error-text text-right mt-4">
              {errorMessage}
            </p>
          )}
        </DialogContent>
        <DialogActions className="mb-16">
          <Button variant="contained" onClick={this.onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={this.onSubmitPayment}
          >
            {loading ? "Adding Credit..." : "Add Credit"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default injectStripe(SmsAddCreditModal);

import React from "react";
import Select from "react-select";
import { Navigation, PageURL } from "../lib";

/**
 * @deprecated Use the updated TableResultsView for functional components
 */
export default function TableResultsView({
  totalCount,
  increment,
  resultsView,
  enrollmentID,
  eventID,
  getURL,
  loadData,
  name,
  page,
  pageRoute,
  searchTerm,
  status,
}) {
  const changeResultsView = (event) => {
    if (name === "Contacts") {
      const url = PageURL.to(pageRoute.page, null, {
        ...pageRoute.query,
        p: 1,
        r: event.value,
      });
      Navigation.go(url);
    } else {
      Navigation.redirect(getURL(page, event.value));
      //pass in the event value as the second prop instead of the resultsView coming from props
      setTimeout(
        () =>
          loadData(
            page,
            event.value,
            searchTerm,
            enrollmentID,
            eventID,
            status,
          ),
        0,
      );
      window.scrollTo({ left: 0, top: 0 });
    }
  };

  let options = Array(3)
    .fill()
    .map((_, i) => ++i * increment)
    .map((resultSize) => ({ value: resultSize, label: resultSize }));
  return (
    totalCount > 0 && (
      <div
        className="flex flex-align-center mobile-flex-justify-end"
        style={{ minWidth: 100 }}
      >
        <p className="flex flex-align-center mr-8">View </p>
        <Select
          className={"view-select"}
          classNamePrefix={"view-select"}
          defaultValue={{
            value: resultsView,
            label: resultsView,
          }}
          options={options}
          onChange={changeResultsView}
        />
      </div>
    )
  );
}

import React from "react";
import { Prompt } from "react-router-dom";
import { Typography } from "@material-ui/core";
import ImageUpload from "../../../../components/formik/ImageWithCropUpload";
import Radio from "../../../../components/form/Radio";
import EmailRecipientsSection from "../../../../components/EmailRecipientsSection";
import { BillingAddress, CreditCardInfo } from "../../../../components/billing";
import {
  EduProgramTypes,
  getJewishUMembershipEffectiveThroughYear,
  jewishUMembershipStartDate,
} from "../../eduUtils";
import {
  formatCurrency,
  getFormattedValuesForForm,
  removeEmptyFromObj,
} from "../../../../lib";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

export default class EnrollmentSettings extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      ...this.getInitializedEnrollment(),
      errorMessage: "",
      submitPaymentLoading: false,
      submitAttempted: false,
    };

    state.initialState = _cloneDeep(state);

    this.state = state;
  }

  incompleteErrorMessage = "Please complete required fields";

  getInitializedEnrollment = () => {
    const {
      account: { credCardInfo } = {},
      enrollment: { data: enrollment },
      newEnrollment,
    } = this.props;

    const initializedEnrollment = getFormattedValuesForForm(enrollment);

    // initialize billing details for a new JewishU enrollment
    if (
      newEnrollment &&
      enrollment.eduProgramType === EduProgramTypes.JewishU
    ) {
      initializedEnrollment.billing = {
        amountCents: enrollment.eduProgramSubscriptionFee * 100,
        address: {
          address1: "",
          address2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        cardHolderFullName: "",
        hasCompleteCardInfo: false,
        useCardOnFile: !!credCardInfo,
      };
    }

    return {
      enrollment: initializedEnrollment,
      initialEnrollment: _cloneDeep(initializedEnrollment),
      overrideDefaultImageURL: !!enrollment.eduProgramImageURLOverride,
      overrideDefaultMessage: !!enrollment.eduProgramMessageOverride,
    };
  };

  onChangeEnrollment = (name, value, otherChanges) => {
    if (
      this.state.submitPaymentLoading ||
      this.props.submitEnrollment.loading
    ) {
      return;
    }

    let enrollment = _cloneDeep(this.state.enrollment);
    _set(enrollment, name, value);

    if (otherChanges) {
      Object.keys(otherChanges).forEach((change) =>
        _set(enrollment, change, otherChanges[change]),
      );
    }

    return new Promise((resolve, reject) => {
      this.setState({ enrollment }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        const { errorMessage } = this.state;
        if (errorMessage && errorMessage === this.incompleteErrorMessage) {
          const isValid = this.validateEnrollment();
          if (isValid) {
            this.setState({
              errorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeEnrollmentEvt = ({ target: { name, value } }) => {
    return this.onChangeEnrollment(name, value);
  };

  onCancelEnrollmentChanges = () => {
    this.setState(this.state.initialState);
  };

  onSubmitEnrollment = async () => {
    this.setState({ errorMessage: "", submitAttempted: true });

    const isValid = this.validateEnrollment();
    if (!isValid) {
      this.setState({
        errorMessage: this.incompleteErrorMessage,
      });
      return;
    }

    const { newEnrollment } = this.props;

    const enrollmentForSubmission = { ...this.state.enrollment };
    if (newEnrollment) {
      //activate new enrollment
      enrollmentForSubmission.isPending = false;

      //get stripe token for payment method processing
      const { billing } = enrollmentForSubmission;
      if (billing) {
        const { address, cardHolderFullName, useCardOnFile } = billing;

        if (!useCardOnFile) {
          try {
            this.setState({ submitPaymentLoading: true });

            const {
              stripe: { createToken },
              sys: { countries },
            } = this.props;
            const stripeValues = {
              name: cardHolderFullName,
              address_line1: address.address1,
              address_line2: address.address2,
              address_city: address.city,
              address_state: address.state,
              address_zip: address.zip,
              address_country: countries?.find(
                (c) => c.name === address.country,
              )?.code,
            };

            const { token } = await createToken(stripeValues);
            enrollmentForSubmission.billing.stripeToken = token;
          } catch (err) {
            this.setState({
              errorMessage:
                "Unable to process credit card payment. Please try again.",
              submitPaymentLoading: false,
            });
            return;
          }
        }
      }
    }
    removeEmptyFromObj(enrollmentForSubmission);

    await this.props.actions.submitEduEnrollment(enrollmentForSubmission);

    this.setState({ submitPaymentLoading: false });

    const {
      submitEnrollment: { error, errorMessage },
      toggleShowEnrollmentCompleted,
    } = this.props;

    if (error) {
      this.setState({
        errorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    } else {
      if (newEnrollment && toggleShowEnrollmentCompleted) {
        toggleShowEnrollmentCompleted();
      } else {
        const updatedState = {
          ...this.state.initialState,
          ...this.getInitializedEnrollment(),
        };
        this.setState(updatedState);
      }
    }
  };

  toggleOverrideDefaultSetting = (name, value, overrideFieldName) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (!value && this.state.enrollment[overrideFieldName]) {
          this.onChangeEnrollment(overrideFieldName, "");
        }
      },
    );
  };

  validateEnrollment = () => {
    const { newEnrollment } = this.props;
    const {
      enrollment: {
        billing,
        eduProgramImageURLOverride,
        eduProgramMessageOverride,
        eduProgramType,
        emailRecipients,
      },
      overrideDefaultImageURL,
      overrideDefaultMessage,
    } = this.state;

    if (
      emailRecipients.length === 0 ||
      (overrideDefaultMessage && !eduProgramMessageOverride) ||
      (overrideDefaultImageURL && !eduProgramImageURLOverride)
    ) {
      return false;
    }

    // validate billing for a new JewishU enrollment
    if (newEnrollment && eduProgramType === EduProgramTypes.JewishU) {
      if (!billing) return false;

      const {
        address: { address1, city, country },
        cardHolderFullName,
        hasCompleteCardInfo,
        useCardOnFile,
      } = billing;
      if (
        !useCardOnFile &&
        (!address1 ||
          !city ||
          !country ||
          !hasCompleteCardInfo ||
          !cardHolderFullName)
      ) {
        return false;
      }
    }

    return true;
  };

  render() {
    const {
      account: { credCardInfo, familyAddress, primaryChabadHouse } = {},
      newEnrollment,
      submitEnrollment: { loading: submitEnrollmentLoading },
      sys: { countries = [] },
    } = this.props;

    const {
      enrollment,
      errorMessage,
      initialEnrollment,
      initialState,
      overrideDefaultImageURL,
      overrideDefaultMessage,
      submitPaymentLoading,
      submitAttempted,
    } = this.state;

    const {
      billing,
      chabadHouseID,
      defaultImageURL,
      defaultMessage,
      eduProgramImageURLOverride,
      eduProgramMessageOverride,
      eduProgramName,
      eduProgramSubscriptionFee,
      eduProgramType,
      emailRecipients,
      websiteURL,
    } = enrollment;

    const loading = submitEnrollmentLoading || submitPaymentLoading;

    const hasChanges =
      !newEnrollment &&
      (!_isEqual(initialEnrollment, enrollment) ||
        overrideDefaultImageURL !== initialState.overrideDefaultImageURL ||
        overrideDefaultMessage !== initialState.overrideDefaultMessage);

    const isJewishUEnrollment = eduProgramType === EduProgramTypes.JewishU;

    return (
      <div>
        <div className="edu-card card enrollment-card">
          <Prompt
            when={hasChanges}
            message="Your changes have not been saved. Are you sure you want to leave this page?"
          />
          {newEnrollment ? (
            <h2 className="page-title line-height-double">
              Welcome to {eduProgramName}!
              <span className="accent-text-dark medium-text fw-400 block">
                First, let's set up your website
              </span>
            </h2>
          ) : (
            <h2 className="page-title">
              {isJewishUEnrollment ? "Webpage Settings" : "Settings"}
            </h2>
          )}
          {isJewishUEnrollment && (
            <>
              <div className="edu-form-section">
                <Typography variant="h6" className="mb-8">
                  Main Message
                </Typography>
                <p className="accent-text-dark mb-24 line-height-double">
                  The main message will be displayed in large on the top section
                  of your Chabad House's {eduProgramName} web page.
                </p>
                <div>
                  <Radio
                    name="overrideDefaultMessage"
                    onChange={(name, value) =>
                      this.toggleOverrideDefaultSetting(
                        name,
                        value,
                        "eduProgramMessageOverride",
                      )
                    }
                    options={[
                      {
                        value: false,
                        display: "Use the default main message",
                        content: (
                          <p className="small-text mb-24 ml-40 accent-text line-height-double pre-wrap">
                            {defaultMessage}
                          </p>
                        ),
                      },
                      {
                        value: true,
                        display: "Create custom main message",
                        content: (
                          <React.Fragment>
                            <textarea
                              className="custom-input custom-message mt-8"
                              disabled={!overrideDefaultMessage}
                              value={eduProgramMessageOverride}
                              placeholder="Type custom main message"
                              name="eduProgramMessageOverride"
                              onChange={this.onChangeEnrollmentEvt}
                            />
                            {submitAttempted &&
                              overrideDefaultMessage &&
                              !eduProgramMessageOverride && (
                                <p className="error-message">
                                  please enter your custom main message
                                </p>
                              )}
                          </React.Fragment>
                        ),
                      },
                    ]}
                    value={overrideDefaultMessage}
                  />
                </div>
              </div>
              <div className="edu-form-section">
                <Typography variant="h6" className="mb-8">
                  Main Image
                </Typography>
                <p className="accent-text-dark mb-24 line-height-double">
                  The main image will be displayed in large at the top section
                  of your Chabad House's {eduProgramName} web page.
                </p>
                <div>
                  <Radio
                    name="overrideDefaultImageURL"
                    onChange={(name, value) =>
                      this.toggleOverrideDefaultSetting(
                        name,
                        value,
                        "eduProgramImageURLOverride",
                      )
                    }
                    options={[
                      {
                        value: false,
                        display: "Use the default main image",
                        content: (
                          <div className="image-upload-container ml-40 mt-8 mb-24">
                            <img src={defaultImageURL} alt="" />
                          </div>
                        ),
                      },
                      {
                        value: true,
                        display: "Choose custom main image",
                        content: (
                          <React.Fragment>
                            <div className="ml-40 mt-8 mb-24">
                              <ImageUpload
                                className={
                                  !overrideDefaultImageURL ? "disabled" : ""
                                }
                                cropAspectWidth={3}
                                cropAspectHeight={1}
                                disabled={!overrideDefaultImageURL}
                                imageType="banner"
                                imageURL={
                                  eduProgramImageURLOverride
                                    ? eduProgramImageURLOverride
                                    : null
                                }
                                onChange={this.onChangeEnrollment}
                                label="Upload Image"
                                name="eduProgramImageURLOverride"
                                uploadedLabel="Change Image"
                              />
                            </div>
                            {submitAttempted &&
                              overrideDefaultImageURL &&
                              !eduProgramImageURLOverride && (
                                <p className="error-message">
                                  please choose a custom main image
                                </p>
                              )}
                          </React.Fragment>
                        ),
                      },
                    ]}
                    value={overrideDefaultImageURL}
                  />
                </div>
              </div>
            </>
          )}
          <EmailRecipientsSection
            chabadHouseID={chabadHouseID}
            emailRecipients={emailRecipients || []}
            formSectionClassName="edu-form-section"
            onChange={(recipientsChange) =>
              this.onChangeEnrollment("emailRecipients", recipientsChange)
            }
            submitAttempted={submitAttempted}
          />
          {newEnrollment ? (
            isJewishUEnrollment && (
              <div className="edu-form-section">
                <Typography variant="h6" className="mb-8">
                  Membership and fees
                </Typography>
                <p className="accent-text-dark mb-24 line-height-double">
                  Choose your payment method. Your membership is valid until
                  {jewishUMembershipStartDate},{" "}
                  {getJewishUMembershipEffectiveThroughYear()}.
                </p>
                <Typography variant="h6" className="mb-16">
                  Annual membership - $
                  {formatCurrency(eduProgramSubscriptionFee)}
                </Typography>
                <div className="mb-24">
                  <CreditCardInfo
                    billing={billing}
                    cardOnFile={credCardInfo}
                    onChange={this.onChangeEnrollment}
                    onChangeEvt={this.onChangeEnrollmentEvt}
                    submitAttempted={submitAttempted}
                  />
                </div>
                {!billing?.useCardOnFile && (
                  <BillingAddress
                    billing={billing}
                    chabadHouseAddress={primaryChabadHouse?.address}
                    countries={countries}
                    familyAddress={familyAddress}
                    onChange={this.onChangeEnrollment}
                    onChangeEvt={this.onChangeEnrollmentEvt}
                    submitAttempted={submitAttempted}
                  />
                )}
              </div>
            )
          ) : (
            <div className="edu-form-section">
              <p className="medium-text fw-700 mb-8">Your Webpage Url</p>
              <div className="flex flex-align-center tablet-block mobile-block">
                <p className="accent-text-dark mr-24 tablet-mt-8 tablet-mb-8 mobile-mt-8 mobile-mb-8">
                  Your Chabad House's Url is
                </p>
                <div className="raffles-link flex flex-align-center flex-justify-space">
                  <p>{websiteURL}</p>
                  <a
                    className="link-text small-text flex flex-align-center ml-16 no-wrap"
                    href={websiteURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="material-icons medium-text mr-8">
                      open_in_new
                    </i>
                    Go to Link
                  </a>
                </div>
              </div>
              {!!chabadHouseID && (
                <p className="small-text accent-text-dark mt-8">
                  The URL Friendly Name (the part of the URL that references
                  your Chabad House) can be updated in your Chabad House profile
                  by clicking{" "}
                  <a
                    className="link-text"
                    href={`/account`} //TODO scroll to chabad house section
                  >
                    here
                  </a>
                </p>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-justify-end relative edu-form-btns">
          {newEnrollment ? (
            <button
              className="btn btn-accent btn-large mt-40"
              style={{ padding: "0 32px" }}
              onClick={this.onSubmitEnrollment}
            >
              {loading
                ? "Activating..."
                : isJewishUEnrollment
                ? "Pay & Activate"
                : "Activate"}
            </button>
          ) : (
            <React.Fragment>
              <button
                className="btn btn-light btn-large mt-40"
                disabled={loading || !hasChanges}
                style={{ padding: "0 32px" }}
                onClick={this.onCancelEnrollmentChanges}
              >
                Cancel
              </button>
              <button
                className="btn btn-accent btn-large mt-40 ml-16"
                disabled={loading || !hasChanges}
                style={{ padding: "0 32px" }}
                onClick={this.onSubmitEnrollment}
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </React.Fragment>
          )}
          {submitAttempted && !loading && errorMessage && (
            <p className="error-message">{errorMessage}</p>
          )}
        </div>
      </div>
    );
  }
}

import React, { Fragment, memo, useMemo } from "react";
import { Cell, PieChart, Pie, ResponsiveContainer } from "recharts";
import { Box, Typography } from "@material-ui/core";
import {
  BillingCardStyled,
  RewardsChartKeyStyled,
  RewardsLineChartContainerStyled,
} from "./Billing.styles";
import { EduBillingCosts } from "./BillingTypes";
import { formatCurrency, pluralizeText } from "../../../../lib";
import { useMobile } from "../../../../themes";

const RewardColors: Record<string, string> = {
  Charity: "#4fcec5",
  Cash: "#ff6b6b",
  Programs: "#7c71f9",
  Trip: "#2774ae",
};

interface CreditsBillingRewardsChartProps {
  billingCosts: EduBillingCosts;
}

function CreditsBillingRewardsChart({
  billingCosts,
}: CreditsBillingRewardsChartProps) {
  const isMobile = useMobile("sm");

  const { costsByRewardType, totalNumberOfCredits } = billingCosts;

  const chartData = useMemo(() => {
    return costsByRewardType.map((costs) => {
      const { rewardType, rewardTypeDisplay, numberOfCredits } = costs;
      return {
        ...costs,
        color: RewardColors[rewardType],
        // pie chart requires name and value props
        name: rewardTypeDisplay,
        value: numberOfCredits,
      };
    });
  }, [costsByRewardType]);

  return (
    <BillingCardStyled width="100%" maxWidth={isMobile ? undefined : "400px"}>
      <Typography
        variant="h6"
        className={isMobile || !chartData.length ? undefined : "mb-64"}
      >
        {isMobile ? "Reward types" : "Redemptions by reward type"}
      </Typography>
      {chartData.length ? (
        isMobile ? (
          <>
            <RewardsLineChartContainerStyled>
              {chartData.map((data) => {
                const linePercentage =
                  (data.numberOfCredits * 100) / totalNumberOfCredits;
                return (
                  <Box
                    key={data.rewardType}
                    style={{
                      backgroundColor: data.color,
                      width: `${linePercentage}%`,
                    }}
                  >
                    <Typography variant="caption" style={{ color: data.color }}>
                      {data.numberOfCredits}
                      <br />
                      <span style={{ fontWeight: 600 }}>|</span>
                    </Typography>
                  </Box>
                );
              })}
            </RewardsLineChartContainerStyled>
            <RewardsChartKeyStyled>
              {chartData.map((data) => (
                <Box key={data.rewardType}>
                  <Box className="flex flex-align-center flex-justify-space">
                    <Typography variant="subtitle2">
                      <i
                        className="material-icons"
                        style={{ color: data.color }}
                      >
                        square
                      </i>
                      {data.rewardTypeDisplay}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {data.numberOfCredits}{" "}
                      {pluralizeText("credit", data.numberOfCredits)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" color="textSecondary">
                      Value:{" "}
                    </Typography>
                    <Typography variant="caption">
                      ${formatCurrency(data.value)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {" | "}COCI cost:{" "}
                    </Typography>
                    <Typography variant="caption">
                      ${formatCurrency(data.cociCost)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {" | "}My cost:{" "}
                    </Typography>
                    <Typography variant="caption">
                      ${formatCurrency(data.chabadHouseCost)}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </RewardsChartKeyStyled>
          </>
        ) : (
          <>
            <ResponsiveContainer width="100%" height={280}>
              <PieChart>
                <Pie
                  data={chartData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  label
                  labelLine={false}
                >
                  {chartData.map((data, index) => (
                    <Cell key={index} fill={data.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <RewardsChartKeyStyled marginTop="64px">
              {["Type", "Value", "COCI cost", "My cost"].map((header) => (
                <Typography key={header} variant="subtitle2">
                  {header}
                </Typography>
              ))}
              {chartData.map((data) => (
                <Fragment key={data.rewardType}>
                  <Typography variant="body2">
                    <i className="material-icons" style={{ color: data.color }}>
                      square
                    </i>
                    {data.rewardTypeDisplay}
                  </Typography>
                  <Typography variant="body2">
                    ${formatCurrency(data.cociCost + data.chabadHouseCost)}
                  </Typography>
                  <Typography variant="body2">
                    ${formatCurrency(data.cociCost)}
                  </Typography>
                  <Typography variant="body2">
                    ${formatCurrency(data.chabadHouseCost)}
                  </Typography>
                </Fragment>
              ))}
            </RewardsChartKeyStyled>
          </>
        )
      ) : (
        <Typography
          variant="subtitle2"
          className="mt-16 mb-16 mobile-text-center"
        >
          No redemptions found for this billing period
        </Typography>
      )}
    </BillingCardStyled>
  );
}

export default memo(CreditsBillingRewardsChart);

import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import Loader from "../../../components/Loader";
import { formatCurrency, formatNumber } from "../../../lib/utils";

const barColors = [
  { dark: "#6369D1", light: "rgba(99,105,209,0.08)" },
  { dark: "#63C674", light: "rgba(99,198,116,0.08)" },
  { dark: "#EC5150", light: "rgba(236,81,80,0.08)" },
  { dark: "#F3893D", light: "rgba(243,137,61,0.08)" },
  { dark: "#53B7E8", light: "rgba(83,183,232,0.08)" },
];

function getBarColor(index) {
  return (
    barColors[index < 5 ? index : index % 5] || {
      dark: "rgba(0,0,0,0.87)",
      light: "#F4F4F4",
    }
  );
}

class CustomSalesByChabadHouseLabel extends React.Component {
  render() {
    const { data, getChabadHouseLabel, height, index, x, y } = this.props;
    const fill = getBarColor(index).dark;
    const { currencyCode, totalRaised, totalTicketsSold } = data[index];

    const mobileView = window.innerWidth < 800;
    const fontSize = mobileView ? 12 : 14;

    if (mobileView) {
      const mobileY = y - 8;
      return (
        <>
          <text
            dx={5}
            dy={height / 2 + fontSize / 2}
            fill={fill}
            fontSize={fontSize}
            fontFamily="sans-serif"
            textAnchor="start"
            x={x ? x : ""}
            y={mobileY}
          >
            {getChabadHouseLabel(data[index], window.innerWidth < 800)}
          </text>
          <text
            dx={5}
            dy={height / 2 + fontSize / 2}
            fill={fill}
            fontSize={fontSize}
            fontFamily="sans-serif"
            textAnchor="start"
            x={x ? x : ""}
            y={mobileY + 14}
          >
            {formatNumber(totalTicketsSold)} /{" "}
            {formatCurrency(totalRaised, currencyCode)}
          </text>
        </>
      );
    }

    return (
      <text
        dx={5}
        dy={height / 2 + fontSize / 2}
        fill={fill}
        fontSize={fontSize}
        fontFamily="sans-serif"
        textAnchor="start"
        x={x ? x : ""}
        y={y}
      >
        {getChabadHouseLabel(data[index], window.innerWidth < 800)} (
        {formatNumber(totalTicketsSold)} /{" "}
        {formatCurrency(totalRaised, currencyCode)})
      </text>
    );
  }
}

export default class RaffleTopSellersChart extends React.Component {
  getChabadHouseLabel = (
    { chabadHouseName, shliachFirstName, shliachLastName, shluchaFirstName },
    mobileView = false,
  ) => {
    let label = chabadHouseName;

    if (!mobileView) {
      label += ` - ${shliachFirstName || ""}${
        shliachFirstName && shluchaFirstName
          ? ` and ${shluchaFirstName}`
          : shluchaFirstName
          ? ` ${shluchaFirstName}`
          : ""
      } ${shliachLastName}`;
    } else if (window.innerWidth < 400 && label.length > 45) {
      label = label.substring(0, 45) + "...";
    } else if (window.innerWidth < 500 && label.length > 55) {
      label = label.substring(0, 55) + "...";
    }

    return label;
  };

  render() {
    const { data, loading } = this.props;

    return (
      <div className="mt-40 graph-outline" style={{ minHeight: "480px" }}>
        <p className="medium-text fw-700">
          Top{data.length < 10 ? "" : " 10"} Sales by Chabad House
        </p>

        {loading ? (
          <Loader />
        ) : !data.length ? (
          <div className="chart-no-results">
            <img src="/images/no_results.svg" alt="no results" height="120" />
            <p className="accent-text mt-8">No sales found</p>
          </div>
        ) : (
          <ResponsiveContainer height={440} width="94%">
            <BarChart data={data} layout="vertical">
              <Bar
                dataKey="totalTicketsSold"
                fill="#8884d8"
                label={
                  <CustomSalesByChabadHouseLabel
                    data={data}
                    getChabadHouseLabel={this.getChabadHouseLabel}
                  />
                }
              >
                {data.map((_, index) => {
                  const fill = getBarColor(index).light;
                  return <Cell key={index} fill={fill} />;
                })}
              </Bar>

              <YAxis dataKey={this.getChabadHouseLabel} hide type="category" />

              <XAxis
                dataKey="totalTicketsSold"
                tickFormatter={this.formatSales}
                type="number"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    );
  }
}

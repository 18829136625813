import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";
import queryString from "query-string";
import { SysActions } from "../sys/actions";
import { composeFilterParams } from "../../pages/raffle/raffleUtils";

export const RaffleActions = {
  SET_RAFFLE_LOADING: "SET_RAFFLE_LOADING",
  SET_RAFFLE_DETAILS_LOADING: "SET_RAFFLE_DETAILS_LOADING",
  SET_RAFFLE_ORDERS_LOADING: "SET_RAFFLE_ORDERS_LOADING",
  SET_RAFFLE_DONORS_LOADING: "SET_RAFFLE_DONORS_LOADING",
  SET_RAFFLE_METRICS_LOADING: "SET_RAFFLE_METRICS_LOADING",
  SET_RAFFLE_SALES_LOADING: "SET_RAFFLE_SALES_LOADING",
  SET_RAFFLE_SALES_COMPARISON_LOADING: "SET_RAFFLE_SALES_COMPARISON_LOADING",
  SET_RAFFLE_TOP_SELLERS_LOADING: "SET_RAFFLE_TOP_SELLERS_LOADING",
  SET_RAFFLE_TEAMS_LOADING: "SET_RAFFLE_TEAMS_LOADING",
  UPDATE_RAFFLE: "UPDATE_RAFFLE",

  getRaffleEnrollment(id, enrollmentID, raffleEventID) {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleLoading(true));
      let success = true;
      let error;
      const { data } = await AuthRequest.get(
        `RaffleEnrollments/shliach/${id}`,
      ).catch((err) => {
        success = false;
        return err;
      });
      if (!enrollmentID || enrollmentID === "undefined") {
        if (data && data.payload.isEnrolledInCurrent) {
          enrollmentID = data.payload.sellerEnrollmentIDForCurrent;
        } else {
          enrollmentID = 0;
        }
      }

      let enrollmentRequestURL = `RaffleEnrollments/${enrollmentID}?returnBlank=true`;
      if (raffleEventID) {
        enrollmentRequestURL += `&raffleEventID=${raffleEventID}`;
      }

      const { data: enrollmentDetails } = await AuthRequest.get(
        enrollmentRequestURL,
      ).catch((err) => {
        success = false;
        error = err;
        return err;
      });
      let newState;
      if (success) {
        const { referrers, ...details } = enrollmentDetails.payload;
        newState = {
          historicEnrollments: data.payload.historicalEnrollments,
          enrollmentDetails: details,
          referrers,
          loading: false,
          success,
        };
      } else {
        newState = {
          loading: false,
          success,
          errorMessage: error.response,
        };
      }

      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  submitEnrollment(updatedEnrollment, refreshBanners) {
    return async (dispatch) => {
      let success = true;
      let error;
      const { data } = await AuthRequest.post(
        `RaffleEnrollments`,
        updatedEnrollment,
      ).catch((err) => {
        success = false;
        error = err;
        return err;
      });
      let newState;
      if (success) {
        newState = {
          enrollmentDetails: {
            ...updatedEnrollment,
            id: data.payload.id,
            campaignMessageOverride: data.payload.campaignMessageOverride,
            emailMessageOverride: data.payload.emailMessageOverride,
            donorSiteIframeText: data.payload.donorSiteIframeText,
            donorSiteURL: data.payload.donorSiteURL,
            success,
          },
        };
      } else {
        newState = {
          enrollmentDetails: {
            ...updatedEnrollment,
            success,
            errorMessage: error.response,
          },
          success,
          errorMessage: error.response,
        };
      }

      dispatch(RaffleActions.updateRaffle(newState));

      if (refreshBanners) {
        dispatch(SysActions.getSystemData());
      }
      return { data, success, errors: error?.response?.data?.messages || [] };
    };
  },

  getRaffleTeams(sellerEnrollmentId) {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleTeamsLoading(true));
      let success = true;
      const { data } = await AuthRequest.get(
        `RaffleEnrollments/${sellerEnrollmentId}/referrers`,
      ).catch((err) => {
        success = false;
        return err;
      });
      const newState = {
        referrers: data?.payload,
        loading: false,
        success,
      };
      dispatch(RaffleActions.updateRaffle(newState));
    };
  },

  submitRaffleTeam(teamForSubmission) {
    return async (dispatch) => {
      const newState = { loading: false, success: true };
      await AuthRequest.post(
        `RaffleEnrollments/referrers`,
        teamForSubmission,
      ).catch((err) => {
        newState.success = false;
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
      });
      if (newState.success) {
        dispatch(
          RaffleActions.getRaffleTeams(teamForSubmission.sellerEnrollmentId),
        );
      }
      dispatch(RaffleActions.updateRaffle({ createTeam: newState }));
      return newState;
    };
  },
  deleteRaffleTeam(teamId, sellerEnrollmentId) {
    return async (dispatch) => {
      const newState = { loading: false, success: true };
      await AuthRequest.delete(
        `RaffleEnrollments/${sellerEnrollmentId}/referrers/${teamId}`,
      ).catch((err) => {
        newState.success = false;
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
      });

      if (newState.success) {
        dispatch(RaffleActions.getRaffleTeams(sellerEnrollmentId));
      }
      dispatch(RaffleActions.updateRaffle({ createTeam: newState }));
      return newState;
    };
  },
  getRaffleEnrollmentStatus(id) {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleLoading(true));
      let success = true;
      let error;
      const { data } = await AuthRequest.get(
        `RaffleEnrollments/shliach/${id}`,
      ).catch((err) => {
        success = false;
        error = err;
        return err;
      });
      let newState;
      if (success) {
        newState = {
          isEnrolledInCurrent: data.payload.isEnrolledInCurrent,
          loading: false,
          success,
        };
      } else {
        newState = {
          loading: false,
          success,
          errorMessage: error.response,
        };
      }

      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  getCurrentRaffle() {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleDetailsLoading(true));
      let success = true;
      const { data } = await AuthRequest.get(
        `RaffleEvents/current?onlyRaffleData=true`,
      ).catch((err) => {
        success = false;
        return err;
      });
      let newState;
      if (success) {
        newState = {
          details: {
            ...data.payload,
            loading: false,
            success,
          },
        };
      } else {
        newState = {
          success,
          details: {
            loading: false,
          },
        };
      }
      dispatch(RaffleActions.updateRaffle(newState));
      return newState;
    };
  },
  getRaffleEvent(id) {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleDetailsLoading(true));
      let success = true;
      const { data } = await AuthRequest.get(`RaffleEvents/${id}`).catch(
        (err) => {
          success = false;
          return err;
        },
      );
      let newState;
      if (success) {
        newState = {
          details: {
            ...data.payload,
            loading: false,
          },
          success,
        };
      } else {
        newState = {
          details: {
            loading: false,
          },
          success,
        };
      }
      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  getHistoricEnrollments(id) {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleLoading(true));
      let success = true;
      const { data } = await AuthRequest.get(
        `RaffleEnrollments/shliach/${id}`,
      ).catch((err) => {
        success = false;
        return err;
      });
      const newState = {
        historicEnrollments: data?.payload.historicalEnrollments,
        success,
        loading: false,
      };

      dispatch(RaffleActions.updateRaffle(newState));
      return data?.payload.historicalEnrollments;
    };
  },
  getHistoricRaffle(id, raffleID) {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleDetailsLoading(true));
      let success = true;
      const { data } = await AuthRequest.get(
        `RaffleEnrollments/${id}?returnBlank=true`,
      ).catch((err) => {
        success = false;
        return err;
      });
      const { data: details } = await AuthRequest.get(
        `RaffleEvents/${raffleID}`,
      ).catch((err) => {
        success = false;
        return err;
      });
      if (success) {
        const { referrers, ...enrollmentDetails } = data.payload;
        const newState = {
          details: {
            ...details.payload,
            loading: false,
          },
          enrollmentDetails: {
            ...enrollmentDetails,
            success,
          },
          referrers,
          success,
        };

        dispatch(RaffleActions.updateRaffle(newState));
      }
    };
  },
  getRaffleOrders(
    page,
    results,
    searchTerm,
    enrollmentID,
    raffleID,
    referrerID,
    paymentType,
    cashTypeID,
    association,
    isDonorThanked,
    isOnlineDonor,
    sortByOption,
  ) {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleOrdersLoading(true));
      let success = true;
      const params = {
        page: page,
        results: results,
        enrollmentID: enrollmentID,
        text: searchTerm || "",
        sortByOption,
        referrerID,
        paymentType,
        cashTypeID,
        association,
        isDonorThanked,
        isOnlineDonor,
        raffleEventID: raffleID,
      };
      const { data } = await AuthRequest.get(
        `RaffleOrders?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        return err;
      });
      const newState = {
        orders: {
          ...data?.payload,
          loading: false,
        },
        success,
      };
      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  getRaffleOrdersForExport(enrollmentID, searchTerm, asTickets, raffleID) {
    return async (dispatch) => {
      let success = true;
      const params = {
        enrollmentID: enrollmentID,
        text: searchTerm,
        sortByOption: "dateDesc",
        export: true,
        asTickets: asTickets,
        raffleEventID: raffleID,
      };
      const { data } = await AuthRequest.get(
        `RaffleOrders?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        return err;
      });
      const newState = {
        ordersForExport: {
          ...data?.payload,
        },
        success,
      };
      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  createOrder(orderForSubmission, filterParams) {
    return async (dispatch) => {
      let success = true;
      let error, errorMessage;
      const createOrderResponse = await AuthRequest.post(
        `RaffleOrders`,
        orderForSubmission,
      ).catch((err) => {
        success = false;
        error = err;
        errorMessage = ApiCallErrorMessageHandler(err);
        return err;
      });
      let newState;
      if (success) {
        const createOrderData = createOrderResponse && createOrderResponse.data;
        newState = {
          createOrder: {
            success,
            data: createOrderData,
          },
        };
        if (createOrderData && !createOrderData.requiresAction) {
          const filterArray = composeFilterParams(filterParams);
          dispatch(RaffleActions.getRaffleOrders(...filterArray));
          dispatch(
            RaffleActions.updateRaffleAmounts(
              orderForSubmission.sellerEnrollmentID,
            ),
          );
        }
      } else {
        newState = {
          createOrder: {
            success,
            error: error.response,
            errorMessage: errorMessage,
          },
        };
      }
      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  resendOrderEmail(orderId) {
    return async () => {
      const newState = {
        success: true,
      };

      await AuthRequest.post(`RaffleOrders/${orderId}/resendEmail`, null).catch(
        (err) => {
          newState.success = false;
          newState.error = err;
          newState.errorMessage = ApiCallErrorMessageHandler(err);
        },
      );

      return newState;
    };
  },
  updateRaffleAmounts(id) {
    return async (dispatch) => {
      let success;
      const { data } = await AuthRequest.get(
        `RaffleEnrollments/${id}?returnBlank=true`,
      ).catch((err) => {
        success = false;
        return err;
      });

      const newState = {
        enrollmentDetails: {
          ...data?.payload,
          success,
        },
      };

      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  importBulkOrders(enrollmentID, bulkOrders, onUploadProgress) {
    return async (dispatch) => {
      let success = true;
      let error, errorMessage;
      const params = {
        enrollmentID,
        timeZoneOffset: new Date().getTimezoneOffset(),
      };

      const formData = new FormData();
      formData.append("file", bulkOrders);

      const { data } = await AuthRequest.post(
        `RaffleOrders/bulkImport?${queryString.stringify(params)}`,
        formData,
        {
          "Content-Type": "multipart/form-data",
          onUploadProgress,
        },
      ).catch((err) => {
        success = false;
        error = err;
        errorMessage = ApiCallErrorMessageHandler(err);
        return err;
      });
      let newState;
      if (success) {
        newState = {
          bulkImportOrders: {
            ...data.payload,
            errorMessage: "",
            success,
          },
        };
      } else {
        newState = {
          bulkImportOrders: {
            error: error.response,
            errorMessage,
            success,
          },
        };
      }

      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  getRaffleMetrics(id) {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleMetricsLoading(true));
      let success = true;
      const { data } = await AuthRequest.get(
        `RaffleEnrollments/metrics/${id}`,
      ).catch((err) => {
        success = false;
        return err;
      });
      const newState = {
        metrics: { ...data?.payload, success },
        metricsLoading: false,
      };
      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  getRaffleSales(id, timeFrame, raffleID) {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleSalesLoading(true));
      let success = true;
      const { data } = await AuthRequest.get(
        `RaffleEnrollments/salesovertime/${id}?RangeType=${timeFrame}${
          raffleID ? `&RaffleEventID=${raffleID}` : ""
        }`,
      ).catch((err) => {
        success = false;
        return err;
      });
      const newState = {
        sales: data?.payload,
        salesLoading: false,
        success,
      };
      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  getRaffleSalesComparison(id, includeAllRaffleEvents, raffleEventID) {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleSalesComparisonLoading(true));

      let newState = {
        salesComparisonLoading: false,
        success: true,
      };

      const params = {
        includeAllEvents: includeAllRaffleEvents,
        eventID: raffleEventID,
      };
      const response = await AuthRequest.get(
        `RaffleEnrollments/salesOverTime/yearToYearComparison/${id}?${queryString.stringify(
          params,
        )}`,
      ).catch(() => {
        newState.success = false;
      });

      if (newState.success) {
        newState.salesComparison = response.data.payload;
      }

      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  getRaffleTopSellers(id) {
    return async (dispatch) => {
      dispatch(RaffleActions.setRaffleTopSellersLoading(true));

      const newState = {
        topSellersLoading: false,
        success: true,
      };

      const response = await AuthRequest.get(
        `RaffleEvents/topSellers/${id}`,
      ).catch(() => {
        newState.success = false;
      });

      if (newState.success) {
        newState.topSellers = response.data.payload;
      }

      dispatch(RaffleActions.updateRaffle(newState));
    };
  },
  getRaffleDonors(
    page,
    results,
    searchTerm,
    sortByOption,
    isExport,
    showAllYears,
    timeframe,
  ) {
    return async (dispatch) => {
      if (!isExport) {
        dispatch(RaffleActions.setRaffleDonorsLoading(true));
      }
      let success = true;
      let params = {};
      if (isExport) {
        params = {
          text: searchTerm || "",
          sortByOption,
          isExport,
        };
      } else {
        params = {
          page: page,
          results: results,
          text: searchTerm || "",
          sortByOption,
          showAllYears,
          timeframe,
        };
      }
      const { data } = await AuthRequest.get(
        `RaffleEnrollments/donors/?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        return err;
      });
      let newState = {};
      if (!isExport) {
        newState = {
          donors: {
            ...data?.payload,
            loading: false,
          },
          success,
        };
      }
      dispatch(RaffleActions.updateRaffle(newState));
      return data?.payload?.results;
    };
  },
  getRaffleDonorDetails(donorEmail, showAllYears) {
    return async (dispatch) => {
      const { data } = await AuthRequest.get(
        `RaffleEnrollments/donors/details?${queryString.stringify({
          donorEmail,
          showAllYears,
        })}`,
      ).catch((err) => {
        return err;
      });
      return data.payload;
    };
  },
  setRaffleLoading(payload) {
    return {
      type: RaffleActions.SET_RAFFLE_LOADING,
      payload,
    };
  },
  setRaffleDetailsLoading(payload) {
    return {
      type: RaffleActions.SET_RAFFLE_DETAILS_LOADING,
      payload,
    };
  },
  setRaffleOrdersLoading(payload) {
    return {
      type: RaffleActions.SET_RAFFLE_ORDERS_LOADING,
      payload,
    };
  },
  setRaffleDonorsLoading(payload) {
    return {
      type: RaffleActions.SET_RAFFLE_DONORS_LOADING,
      payload,
    };
  },
  setRaffleMetricsLoading(payload) {
    return {
      type: RaffleActions.SET_RAFFLE_METRICS_LOADING,
      payload,
    };
  },
  setRaffleSalesLoading(payload) {
    return {
      type: RaffleActions.SET_RAFFLE_SALES_LOADING,
      payload,
    };
  },
  setRaffleSalesComparisonLoading(payload) {
    return {
      type: RaffleActions.SET_RAFFLE_SALES_COMPARISON_LOADING,
      payload,
    };
  },
  setRaffleTopSellersLoading(payload) {
    return {
      type: RaffleActions.SET_RAFFLE_TOP_SELLERS_LOADING,
      payload,
    };
  },
  setRaffleTeamsLoading(payload) {
    return {
      type: RaffleActions.SET_RAFFLE_TEAMS_LOADING,
      payload,
    };
  },
  updateRaffle(payload) {
    return {
      type: RaffleActions.UPDATE_RAFFLE,
      payload,
    };
  },
};

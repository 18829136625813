import React, { memo, useCallback, useState } from "react";
import { Tooltip, Typography } from "@material-ui/core";
import StudentDetailsModal from "../students/StudentDetailsModal";
import PaginatedList from "../../../../components/PaginatedList";
import { BillingCardStyled, InvoicesListRowStyled } from "./Billing.styles";
import { formatDateRangeParams } from "./CreditsBilling";
import { EduBillingInvoice } from "./BillingTypes";
import { EduProgramTypes } from "../../eduUtils";
import { EduActions, useAppDispatch } from "../../../../state";
import { Navigation, PageURL, useQuery } from "../../../../lib";
import { useMobile } from "../../../../themes";
import moment from "moment";

const InvoicePaymentStatuses = {
  Pending: "Pending",
  Failed: "Failed",
  Succeeded: "Succeeded",
};

const listColumnHeaders = [
  "Student",
  "Redeemed",
  "Reward Type",
  "Credits",
  "Value",
  "COCI cost",
  "My cost",
];

interface CreditsBillingInvoicesListProps {
  dateRange: Date[];
  enrollmentId: number;
  pageRoute: any;
}

function CreditsBillingInvoicesList({
  dateRange,
  enrollmentId,
  pageRoute,
}: CreditsBillingInvoicesListProps) {
  const dispatch = useAppDispatch();
  const isMobile = useMobile("sm");
  const { studentId } = useQuery();

  const [page, setPage] = useState(1);
  const [resultsView, setResultsView] = useState(10);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [listData, setListData] = useState({
    results: [] as EduBillingInvoice[],
    numberOfRows: 0,
  });

  const loadInvoices = useCallback(
    async (page: number, resultsView: number) => {
      setLoading(true);
      setPage(page);
      setResultsView(resultsView);

      const { data, error } = await dispatch(
        EduActions.getEduEnrollmentInvoices(enrollmentId, {
          page,
          results: resultsView,
          ...formatDateRangeParams(dateRange),
        }),
      );
      if (error) {
        setError(true);
      } else {
        setListData(data);
      }

      setLoading(false);
    },
    [dateRange, dispatch, enrollmentId],
  );

  const toggleStudentModal = useCallback(
    (studentId?: number) => {
      const { page, params } = pageRoute;
      Navigation.redirect(
        PageURL.to(
          page,
          params,
          studentId ? { studentId, studentTab: EduProgramTypes.JewishU } : {},
        ),
      );
    },
    [pageRoute],
  );

  return (
    <>
      <BillingCardStyled width="100%">
        {isMobile && (
          <Typography variant="h6" className="mb-16">
            Redemptions
          </Typography>
        )}
        <PaginatedList
          loadData={loadInvoices}
          loading={loading}
          name="rewards"
          noRecordsDisplay={
            <Typography variant="subtitle2" className="mt-16 mb-16">
              No redemptions found for this billing period
            </Typography>
          }
          page={page}
          records={listData.results}
          renderHeaderRow={
            isMobile
              ? undefined
              : () => (
                  <InvoicesListRowStyled>
                    {listColumnHeaders.map((header) => (
                      <Typography key={header} variant="subtitle2">
                        {header}
                      </Typography>
                    ))}
                  </InvoicesListRowStyled>
                )
          }
          renderRow={(invoice: EduBillingInvoice) => {
            const {
              billableDate,
              chabadHouseCost,
              cociCost,
              numberOfCredits,
              paymentStatus,
              rewardTypeDisplay,
              studentFirstName,
              studentID,
              studentLastName,
            } = invoice;

            const paymentDueDate =
              paymentStatus === InvoicePaymentStatuses.Pending // payment is charged on the start of the month following the billable date
                ? moment(billableDate)
                    .add(1, "month")
                    .startOf("month")
                    .format("M/D/YY")
                : "";

            const paymentStatusDescription =
              paymentStatus === InvoicePaymentStatuses.Failed
                ? "Payment failed"
                : paymentStatus === InvoicePaymentStatuses.Succeeded
                ? "Paid"
                : `Due on ${paymentDueDate}`;

            const paymentStatusColor =
              paymentStatus === InvoicePaymentStatuses.Failed
                ? "#ff6b6b"
                : paymentStatus === InvoicePaymentStatuses.Succeeded
                ? "#4fcec5"
                : "#aaabb0";

            return (
              <InvoicesListRowStyled key={invoice.id}>
                <Typography
                  className="link-text-secondary"
                  onClick={() => toggleStudentModal(studentID)}
                  variant="body2"
                >
                  {studentFirstName} {studentLastName}
                </Typography>
                {isMobile ? (
                  <>
                    <Typography variant="caption" color="textSecondary">
                      {numberOfCredits} {rewardTypeDisplay} on{" "}
                      {moment(billableDate).format("M/D/YYYY")}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      <i
                        className="material-icons"
                        style={{
                          color: paymentStatusColor,
                        }}
                      >
                        circle
                      </i>{" "}
                      ${chabadHouseCost}{" "}
                      {paymentStatusDescription.toLowerCase()} (COCI cost: $
                      {cociCost})
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body2">
                      {moment(billableDate).format("M/D/YYYY")}
                    </Typography>
                    <Typography variant="body2">{rewardTypeDisplay}</Typography>
                    <Typography variant="body2">{numberOfCredits}</Typography>
                    <Typography variant="body2">
                      ${chabadHouseCost + cociCost}
                    </Typography>
                    <Typography variant="body2">${cociCost}</Typography>
                    <Typography variant="body2">
                      <Tooltip title={paymentStatusDescription}>
                        <i
                          className="material-icons pointer"
                          style={{
                            color: paymentStatusColor,
                          }}
                        >
                          circle
                        </i>
                      </Tooltip>
                      ${chabadHouseCost}
                    </Typography>
                  </>
                )}
              </InvoicesListRowStyled>
            );
          }}
          resultsView={resultsView}
          success={!error}
          totalCount={listData.numberOfRows}
        />
      </BillingCardStyled>
      <StudentDetailsModal
        close={() => toggleStudentModal()}
        pageRoute={pageRoute}
        show={!!studentId}
      />
    </>
  );
}

export default memo(CreditsBillingInvoicesList);

import React, { memo, useEffect, useMemo, useState } from "react";
import MultiSelect from "./form/MultiSelect";
import { AuthRequest } from "../lib";
import { Typography, Collapse } from "@material-ui/core";

const EmailRecipientsSection = (props) => {
  const {
    chabadHouseID,
    disabled = false,
    emailRecipients,
    formSectionClassName,
    onChange,
    showAllExpanded,
    submitAttempted,
    toggleExpand = false,
  } = props;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [staff, setStaff] = useState([]);
  const [showExpanded, setShowExpanded] = useState(
    showAllExpanded || !toggleExpand,
  );

  useEffect(() => {
    if (showAllExpanded !== undefined) {
      setShowExpanded(showAllExpanded);
    }
  }, [showAllExpanded]);

  useEffect(() => {
    (async () => {
      if (chabadHouseID) {
        try {
          setError(false);

          const response = await AuthRequest.get(
            `ChabadHouses/${chabadHouseID}/staff`,
          );

          setStaff(response.data.payload || []);
          setLoading(false);
        } catch (err) {
          setError(true);
          setLoading(false);
        }
      }
    })();
  }, [chabadHouseID]);

  const staffOptions = useMemo(
    () =>
      staff
        ? staff.map((s) => ({
            key: s.personID,
            value: s.personID,
            label: s.firstName + " " + s.lastName,
          }))
        : [],
    [staff],
  );

  const showRequiredError = submitAttempted && !emailRecipients.length;

  const handleToggleExpand = () => {
    setShowExpanded((cur) => !cur);
  };

  return (
    <div className={formSectionClassName}>
      <div
        className={`flex flex-justify-space ${toggleExpand ? "pointer" : ""}`}
        onClick={handleToggleExpand}
      >
        <Typography variant="h6" className="mb-8 flex flex-align-center">
          Email notifications
        </Typography>
        {toggleExpand ? (
          showExpanded ? (
            <i className="material-icons accent-text-secondary xl-text">
              remove
            </i>
          ) : (
            <i className="material-icons accent-text-secondary xl-text">add</i>
          )
        ) : (
          ""
        )}
      </div>
      <Collapse in={showExpanded}>
        <div className={toggleExpand ? "expanded-section" : ""}>
          <Typography
            variant="body2"
            style={{ color: "#747579" }}
            className="mb-16"
          >
            Select all the people who you’d like to receive email notifications.
            Select from shluchim and personnel.{"\n"}
            {!disabled && (
              <>
                If the individual you are looking for is not listed, add
                additional personnel in your Chabad House profile page by
                clicking{" "}
                <a className="link-text" href="/account">
                  here
                </a>
                .{"\n"}
              </>
            )}
          </Typography>
          {staff && (
            <MultiSelect
              className={showRequiredError ? "error" : ""}
              disabled={disabled}
              placeholder={
                loading ? "Loading recipients..." : "Select recipients..."
              }
              onChange={(_, vals) =>
                onChange(vals.map((s) => ({ personID: s.value })))
              }
              options={staffOptions}
              value={staffOptions.filter((s) =>
                emailRecipients.find((r) => r.personID === s.value),
              )}
            />
          )}
          {!loading && !staff?.length ? (
            <p className="small-text accent-text">
              No Chabad House staff found
            </p>
          ) : (
            error && (
              <p className="small-text error-text">
                Something went wrong and Chabad House staff could not be
                retrieved, reload the page to try again
              </p>
            )
          )}
          {showRequiredError && (
            <div className="small-text error-text mt-4">
              Please add at least one email recipient.
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default memo(EmailRecipientsSection);

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { RaffleActions } from "../../state/raffle/actions";
import { ProgramsActions } from "../../state/programs/actions";
import Raffle from "./Raffle";

export default connect(
  function mapState(state) {
    return {
      sys: state.sys,
      shliachID: state.auth.shliachID,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          createOrder: RaffleActions.createOrder,
          getCurrentRaffle: RaffleActions.getCurrentRaffle,
          getHistoricEnrollments: RaffleActions.getHistoricEnrollments,
          getHistoricRaffle: RaffleActions.getHistoricRaffle,
          getProgramRoutes: ProgramsActions.getProgramRoutes,
          getRaffleDonors: RaffleActions.getRaffleDonors,
          getRaffleDonorDetails: RaffleActions.getRaffleDonorDetails,
          getRaffleEvent: RaffleActions.getRaffleEvent,
          getRaffleEnrollment: RaffleActions.getRaffleEnrollment,
          getRaffleMetrics: RaffleActions.getRaffleMetrics,
          getRaffleOrders: RaffleActions.getRaffleOrders,
          getRaffleOrdersForExport: RaffleActions.getRaffleOrdersForExport,
          getRaffleSales: RaffleActions.getRaffleSales,
          getRaffleSalesComparison: RaffleActions.getRaffleSalesComparison,
          getRaffleTopSellers: RaffleActions.getRaffleTopSellers,
          importBulkOrders: RaffleActions.importBulkOrders,
          resendOrderEmail: RaffleActions.resendOrderEmail,
          submitEnrollment: RaffleActions.submitEnrollment,
        },
        dispatch,
      ),
    };
  },
)(Raffle);

import React, { useState, useEffect } from "react";
import { Collapse } from "@material-ui/core";
import Toggle from "../../../components/form/Toggle";

export default function MetricsDisplaySection(props) {
  const { onChange, showAllExpanded, showTopSellers } = props;

  const [showExpanded, setShowExpanded] = useState(showAllExpanded);

  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };

  useEffect(() => {
    setShowExpanded(showAllExpanded);
  }, [showAllExpanded]);

  return (
    <div className="raffles-form-section">
      <div className="raffles-section-title" onClick={handleToggleExpand}>
        <div className="flex">
          <p className="large-text fw-900 flex flex-align-center pointer mb-8">
            Metrics display
          </p>
        </div>
        {showExpanded ? (
          <i className="material-icons accent-text-secondary xl-text">remove</i>
        ) : (
          <i className="material-icons accent-text-secondary xl-text">add</i>
        )}
      </div>

      <Collapse in={showExpanded}>
        <div className="expanded-section">
          <p className="accent-text-dark mb-24 line-height-double">
            Choose whether to display the following metrics:
          </p>
          <div className="flex flex-align-center">
            <p className="fs-13 accent-text" style={{ width: "98px" }}>
              Top sellers
            </p>
            <Toggle
              name="showTopSellers"
              onChange={onChange}
              options={[
                {
                  value: true,
                  display: "Show",
                },
                {
                  value: false,
                  display: "Hide",
                },
              ]}
              value={showTopSellers}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
}
